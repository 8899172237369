import { Error404 as HexaError404 } from '@hexa-ui/components';
import { ComponentProps } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Container, Content } from './404.styles';

interface Error404Props {
  onClick?: ComponentProps<typeof HexaError404>['buttonClickHandler'];
}

export function Error404({ onClick }: Readonly<Error404Props>): JSX.Element {
  const { formatMessage } = useIntl();

  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Container data-testid="error-404-page">
      <Content>
        <HexaError404
          buttonClickHandler={onClick ?? goBack}
          headerText={formatMessage({ id: 'Components.ERROR_404_TITLE' })}
          subHeaderText={formatMessage({ id: 'Components.ERROR_404_DESCRIPTION' })}
          buttonText={formatMessage({ id: 'Components.ERROR_404_BUTTON' })}
        />
      </Content>
    </Container>
  );
}
