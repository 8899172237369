type IsLoading = boolean;

type isEnabled = boolean | undefined;

export type UseFeatureToggleReturnType = [isEnabled, IsLoading];

export enum Features {
  CAD_SHOW_AI_DESCRIPTION = 'cad-show-ai-description',
  CAD_CATEGORY_DETAILS_PRODUCTS_ADD_ENABLED = 'cad-category-details-products-add-enabled',
  CAD_CATEGORY_DETAILS_PRODUCTS_UNASSIGN_ENABLED = 'cad-category-details-products-unassign-enabled',
  CAD_CATEGORY_DETAILS_PRODUCTS_REORDERING_ENABLED = 'cad-category-details-products-reordering-enabled',
  CAD_VARIANT_DETAILS_SINGLE_EDITION_ENABLED = 'cad-variant-details-single-edition-enabled',
  CAD_BULK_FILES_SIZE_LIMIT = 'cad-bulk-files-size-limit',
  CAD_CATEGORY_LISTING_REORDERING_ENABLED = 'cad-category-listing-reordering-enabled',
  CAD_AUDITLOG_ENABLED = 'cad-auditlog-enabled',
}

export type FeatureToggleContextType = {
  useFeatureToggle: (featureName: Features) => UseFeatureToggleReturnType;
};

export type FeatureToggleProviderProps = {
  children: React.ReactNode;
  optimizelyKey: string;
};
