import { getLanguageByCountry } from '@utils/getLanguageByCountry';
import { useAuthenticationService } from 'admin-portal-shared-services';
import Api from 'Api/Api';
import { useApiContext } from 'context';
import { useEffect } from 'react';
import useSWR, { KeyedMutator } from 'swr';
import { v4 as uuid } from 'uuid';

export type UseApiResponseWithMutate<ResponseT> = UseApiResponse<ResponseT> & {
  mutate?: KeyedMutator<ResponseT>;
};

type RequestConfig = {
  cacheInterval?: number;
};

const useApi = <ResponseT>(
  url?: string,
  fetcher?: (url: string) => Promise<ResponseT>,
  customHeaders?: Partial<EnhancedHeaders>,
  requestConfig?: RequestConfig
): UseApiResponseWithMutate<ResponseT> => {
  const authentication = useAuthenticationService();
  const selectedCountry = localStorage.getItem('catalogAdmin-selectedCountry');

  const defaultHeaders: EnhancedHeaders = {
    country: selectedCountry || '',
    noorders: localStorage.getItem('noOrders') || '',
    'Accept-language': getLanguageByCountry(selectedCountry) || '',
    requestTraceId: uuid(),
  };

  const headers: Array<EnhancedHeaders> = [
    {
      ...defaultHeaders,
      ...customHeaders,
    },
  ];

  Api.enhanceService<Array<EnhancedHeaders>>(authentication, headers);

  const { data, error, isLoading, mutate } = useSWR(url, fetcher, {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
    dedupingInterval: requestConfig?.cacheInterval,
  });
  const { setIsLoading } = useApiContext();

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading, setIsLoading]);

  return {
    data,
    error,
    isLoading,
    mutate,
  };
};

export default useApi;
