import useApi from '@hooks/useApi/useApi';
import { CountriesResponse, getCountries } from '@services/countries/CountriesService';
import { TypeToast, usePreferredLanguageV2, useToast } from 'admin-portal-shared-services';
import { BASE_MANAGEMENT_BFF_PATH, URLS } from 'Api/enum';
import { useStoreMap } from 'effector-react';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { RouterParams } from 'Router';
import {
  setCountryOptions,
  setIsLoadingCountryOptions,
  setSelectedCountry,
} from 'stores/country/CountryEvents';
import countryStore from 'stores/country/CountryStore';

const DEFAULT_LANGUAGE = 'en-US';
const COUNTRIES_URL = `${BASE_MANAGEMENT_BFF_PATH}${URLS.COUNTRIES}`;

const useCountry = (): UseCountryType => {
  const {
    error: errorCountries,
    isLoading: isLoadingCountries,
    data,
  } = useApi<CountriesResponse>(COUNTRIES_URL, getCountries);

  const formattedData = useMemo(() => {
    if (!isLoadingCountries && data?.countries?.length > 0) {
      return data.countries.map((country) => country.code);
    }
    return [];
  }, [data, isLoadingCountries]);

  const [isLoadingCountry, setIsLoadingCountry] = useState(true);

  const { country: countryFromURL } = useParams<RouterParams>();
  const { formatMessage } = useIntl();
  const { preferredLanguage, isLoading: isLoadingLanguage } = usePreferredLanguageV2();
  const toast = useToast();

  const { selectedCountry, countryOptions, isLoadingCountryOptions } = useStoreMap({
    store: countryStore,
    keys: [],
    fn: (state) => ({
      selectedCountry: state.selectedCountry,
      countryOptions: state.countryOptions,
      isLoadingCountryOptions: state.isLoading,
    }),
  });

  const isLoadingLanguageOrCountries = isLoadingLanguage || isLoadingCountries;
  const countryFromStorage = localStorage.getItem('catalogAdmin-selectedCountry');

  const handleSetSelectedCountry = (country: string) => {
    localStorage.setItem('catalogAdmin-selectedCountry', country);
    setSelectedCountry(country);
  };

  useEffect(() => {
    if (!isLoadingLanguageOrCountries) {
      const userLanguage = preferredLanguage || DEFAULT_LANGUAGE;
      const regionName = new Intl.DisplayNames([userLanguage], { type: 'region' });

      const translatedCountries = formattedData?.map((country) => ({
        id: country,
        name: regionName.of(country),
      }));

      setCountryOptions(translatedCountries || []);
      setIsLoadingCountryOptions(false);
    }
  }, [isLoadingLanguageOrCountries, preferredLanguage, formattedData]);

  useEffect(() => {
    if (!isLoadingCountryOptions) {
      const formattedCountryFromURL = countryFromURL?.slice(0, 2)?.toUpperCase();
      const isCountryFromStorageSupported = formattedData?.includes(countryFromStorage);
      const storageCountry = isCountryFromStorageSupported ? countryFromStorage : undefined;
      const defaultCountry = formattedCountryFromURL || storageCountry || countryOptions[0]?.id;
      handleSetSelectedCountry(defaultCountry);
      setIsLoadingCountry(false);
    }
  }, [isLoadingCountryOptions, toast]);

  useEffect(() => {
    if (!isLoadingCountryOptions && errorCountries) {
      toast.notify({
        type: TypeToast.ERROR,
        message: formatMessage({ id: 'UseCountry.NO_SUPPORTED_COUNTRIES' }),
      });
    }
  }, [isLoadingCountryOptions, toast, errorCountries, formatMessage]);

  const memoizedValues = useMemo(
    () => ({
      selectedCountry,
      countryOptions,
      isLoadingCountry,
      setSelectedCountry: handleSetSelectedCountry,
    }),
    [selectedCountry, countryOptions, isLoadingCountry]
  );
  return memoizedValues;
};

export default useCountry;
