import { createContext, useContext, useEffect, useRef, useState } from 'react';

interface ActionConfigType {
  interval?: number;
}

export type ActionFunctionProps = {
  results: Record<string, any>;
  actionIds: string[];
};

export type ActionFunction = (props: ActionFunctionProps) => Promise<any>;

type AppendActionType = (
  actionId: string,
  action: ActionFunction,
  config?: ActionConfigType
) => void;

type RemoveActionType = (actionId: string) => void;

type ActionType = Record<string, { action: ActionFunction; config: ActionConfigType }>;

export type ResultType = Record<string, any>;

interface CrossPageActionContextType {
  executeAction: AppendActionType;
  interruptAction: RemoveActionType;
  results: ResultType;
  actionIds: string[];
}

const InterPageActionContext = createContext<CrossPageActionContextType | undefined>(undefined);

const InterPageActionProvider = ({ children }) => {
  const [actions, setActions] = useState<ActionType>({});
  const [results, setResults] = useState<ResultType>({});

  const resultsRef = useRef(results);
  resultsRef.current = results;

  const actionsRef = useRef(actions);
  actionsRef.current = actions;

  const executeAction: AppendActionType = (actionId, action, config) => {
    setActions((prevActions: ActionType) => ({
      ...prevActions,
      [actionId]: { action, config },
    }));
  };

  const interruptAction: RemoveActionType = (actionId) => {
    setActions((prevActions: ActionType) => {
      const { [actionId]: _, ...rest } = prevActions;
      return rest;
    });
  };

  const asyncIntervalAction = async (actionId: string, action: ActionFunction) => {
    const actionResult = await action({
      results: resultsRef.current,
      actionIds: Object.keys(actionsRef.current),
    });
    setResults((prevResults) => ({
      ...prevResults,
      [actionId]: actionResult,
    }));
  };

  useEffect(() => {
    const intervals = {};
    Object.entries(actions).forEach(([actionId, { action, config }]) => {
      if (config?.interval) {
        asyncIntervalAction(actionId, action);
        intervals[actionId] = setInterval(() => {
          asyncIntervalAction(actionId, action);
        }, config.interval);
      } else {
        asyncIntervalAction(actionId, action);
      }
    });
    return () => {
      Object.values(intervals).forEach(clearInterval);
    };
  }, [actions]);

  return (
    <InterPageActionContext.Provider
      value={{ results, executeAction, interruptAction, actionIds: Object.keys(actions) }}
    >
      {children}
    </InterPageActionContext.Provider>
  );
};

export { InterPageActionContext, InterPageActionProvider };

export const useInterPageAction = (): CrossPageActionContextType => {
  const context = useContext(InterPageActionContext);
  if (!context) {
    throw new Error('useInterPageAction must be used inside InterPageActionProvider');
  }
  return context;
};
