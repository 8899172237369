export enum SearchByEnum {
  SKUS = 'skus',
  GTINS = 'gtins',
  VENDOR_ITEM_IDS = 'vendorItemIds',
}

export interface ItemsFilterProps {
  status: string;
  sellers: Sellers[];
  inputText: string;
  searchBy: SearchByEnum;
}

interface ItemsListState {
  items: any;
  filter: ItemsFilterProps;
  isLoading: boolean;
  error?: Error;
}

export default ItemsListState;
