import { TextButton as HexaTextButton } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const TextButton = styled(HexaTextButton, {
  listStyle: 'none',
  fontFamily: 'var(--fontsParagraph)',
  fontSize: '14px !important',
  fontWeight: 'var(--fontWeightsNormal)',
  margin: '0',
  padding: '0',
  boxSizing: 'border-box',
  textDecoration: 'none',
  color: 'var(--colorsInterfaceLabelSecondary)',
  '&:hover': {
    color: '#000000 !important',
  },
  '& svg': {
    fill: 'rgba(0, 0, 0, 0.55) !important',
  },
});
