import { styled } from '@hexa-ui/theme';

export const ItemInformationContent = styled('div', {
  position: 'relative',
  padding: '$2 0 $4',
});

export const ItemInformationFields = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat(2, minmax(230px, 1fr))',
  gap: '24px',
});
