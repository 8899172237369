import qs from 'qs';

export const getQueryString = (queryParams?: Record<string, any>): string => {
  const encodedQueryString = qs.stringify(queryParams, { arrayFormat: 'comma' });
  return decodeURIComponent(encodedQueryString);
};

export const buildApiUrl = (url: string, queryParams?: Record<string, any>): string => {
  const queryString = getQueryString(queryParams);
  return queryString ? `${url}?${queryString}` : url;
};
