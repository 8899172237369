import { Checkbox } from '@hexa-ui/components';
import styled from 'styled-components';

export const StyledButton = styled.button`
  margin: 5px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

export const CheckboxInput = styled(Checkbox)`
  margin-bottom: 15px;
`;

export const FilterContainer = styled.div`
  position: relative;
`;

export const ChipsContainer = styled.div`
  display: flex;
  margin-top: 10px;
  align-items: center;
  gap: 8px;
`;

export const SearchInputContainer = styled.form`
  width: 568px;

  @media (max-width: 1044px) {
    width: 100%;
  }
`;

export const FilterActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: flex-start;
`;

export const FilterActionsLabel = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  margin-right: 8px;
  padding: 4px;
`;

export const FilterActionsInputContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 8px;
  justify-content: flex-start;
`;

export const FilterTitle = styled.h2`
  margin-top: 0;
`;

export const FilterInfo = styled.p`
  margin-bottom: 20px;
`;

export const Divider = styled.hr`
  margin-bottom: 20px;
`;

export const TextInput = styled.input.attrs({ type: 'text' })`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
`;
