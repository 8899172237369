import { createEvent } from 'effector';
import { SearchByEnum } from './ItemsListState';

export const setFilter = createEvent<{
  status: string;
  sellers: Array<Sellers>;
  inputText: string;
  searchBy: SearchByEnum;
}>('setFilter');

export const resetFilter = createEvent<{ defaultSeller?: Sellers }>('resetFilter');

export const resetItemsListStore = createEvent('resetItemsListStore');
