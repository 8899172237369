import { BadgeStatus, IconButton } from 'components';
import {
  BreadcrumbContainer,
  HeaderTitle,
  StyledActions,
  StyledEndGridItemContainer,
  StyledGridContainer,
  StyledGridItem,
  StyledHeading,
} from 'components/pageHeader/PageHeader.styles';
import { PageHeaderProps } from 'components/types/PageHeader';
import { useNavigate } from 'react-router-dom';

function PageHeader({
  title,
  breadcrumb,
  children,
  action,
  prevPageUrl,
  tag,
}: Readonly<PageHeaderProps>): React.ReactElement {
  const navigate = useNavigate();

  const onClickPrevBtn = () => {
    navigate(prevPageUrl);
  };

  return (
    <StyledGridContainer>
      <StyledGridItem lg={6} md={6} sm={6} xl={6} xs={6}>
        <div>
          {breadcrumb && (
            <BreadcrumbContainer data-testid="breadcrumb-container">
              {breadcrumb}
            </BreadcrumbContainer>
          )}
          <HeaderTitle>
            {prevPageUrl && (
              <IconButton
                id="page-header__icon-button"
                name="ArrowLeft"
                variant="inherit"
                onClick={onClickPrevBtn}
              />
            )}
            <StyledHeading data-testid={`${title?.toLowerCase().replace(' ', '-')}-title`}>
              {title}
            </StyledHeading>
            {tag && <BadgeStatus label={tag} color="purple" />}
          </HeaderTitle>
        </div>
      </StyledGridItem>
      <StyledGridItem justify="flex-end" lg={6} md={6} sm={6} xl={6} xs={6}>
        <StyledEndGridItemContainer>
          {children}
          <StyledActions>{action}</StyledActions>
        </StyledEndGridItemContainer>
      </StyledGridItem>
    </StyledGridContainer>
  );
}

export default PageHeader;
