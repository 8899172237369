import { getLanguageByCountry } from '@utils/getLanguageByCountry';
import { useAuthenticationService } from 'admin-portal-shared-services';
import Api from 'Api/Api';
import { v4 as uuid } from 'uuid';

const ApiService = (customHeaders?: CustomHeaders): typeof Api => {
  const authentication = useAuthenticationService();
  const selectedCountry = localStorage.getItem('catalogAdmin-selectedCountry');

  const defaultHeaders: EnhancedHeaders = {
    country: selectedCountry || '',
    noorders: localStorage.getItem('noOrders') || '',
    'Accept-language': getLanguageByCountry(selectedCountry) || '',
    requestTraceId: uuid(),
  };

  const headers: Array<CustomHeaders> = [
    {
      ...defaultHeaders,
      ...customHeaders,
    },
  ];

  Api.enhanceService<Array<CustomHeaders>>(authentication, headers);

  return Api;
};

export { ApiService };
