import { Paragraph } from '@hexa-ui/components';
import React from 'react';
import { GroupAttributeTitle, LabelContainer, OptionalParaghaph } from './FieldTitle.styles';

type FormLabelProps = {
  label: string;
  id: string;
  hasError?: boolean;
  required?: boolean;
  dataTestid?: string;
  isGroup?: boolean;
};

function FieldTitle({
  label,
  id,
  hasError,
  required,
  dataTestid,
  isGroup,
}: Readonly<FormLabelProps>): React.ReactElement {
  return (
    <LabelContainer id={id} data-testid={dataTestid}>
      {isGroup ? (
        <GroupAttributeTitle weight="semibold">{label}</GroupAttributeTitle>
      ) : (
        <Paragraph size="small" colortype={hasError ? 'error' : 'primary'} weight="semibold">
          {label}
        </Paragraph>
      )}
      {!required && <OptionalParaghaph colortype="secondary">(Optional)</OptionalParaghaph>}
    </LabelContainer>
  );
}

export default FieldTitle;
