import { styled } from '@hexa-ui/theme';

export const ItemDetails = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: 4,
  '& p': {
    maxHeight: 22,
  },
  '& p:last-child': {
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
  },
});

export const ListItemDetails = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '$4',
  gap: '$2',
});
