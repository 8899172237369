import { ApiService } from '@services/api/ApiService';
import { buildApiUrl } from '@utils/api';
import { BASE_MANAGEMENT_BFF_PATH, URLS } from 'Api/enum';
import { PatchItemServiceBody, PatchItemServiceParams } from './ItemsService.types';

export const ITEMS_URL = `${BASE_MANAGEMENT_BFF_PATH}${URLS.ITEMS}`;

export const patchItemService = async ({
  id,
  country,
  ...body
}: PatchItemServiceParams): Promise<void> => {
  const { patch } = ApiService({
    country,
  });

  const url = buildApiUrl(`${ITEMS_URL}/${id}`);

  return patch<void | Error, PatchItemServiceBody>({ url, body }).then((response) => {
    if (response instanceof Error) throw response;
    return response;
  });
};
