import Icon from '@components/icon/Icon';
import { Breadcrumb as BreadcrumbHexa } from '@hexa-ui/components';
import { TextButton } from './Breadcrumb.styles';

const Breadcrumb = ({
  currentPage,
  history,
  hasHomeIcon = true,
  onClickHome,
}: BreadcrumbProps): React.ReactElement => {
  return (
    <BreadcrumbHexa.Root>
      {onClickHome ? (
        <BreadcrumbHexa.Item data-testid="breadcrumb-home" asChild>
          <TextButton onClick={onClickHome}>
            <Icon name="Home" />
          </TextButton>
        </BreadcrumbHexa.Item>
      ) : (
        hasHomeIcon && <BreadcrumbHexa.HomeItem data-testid="breadcrumb-home" href="/" />
      )}

      {history?.map((link) => (
        <BreadcrumbHexa.Item data-testid="breadcrumb-item" key={link.linkKey} asChild>
          {link?.onClick ? (
            <TextButton onClick={() => link?.onClick()}>{link.linkTitle}</TextButton>
          ) : (
            <a href={link.linkUrl}>{link.linkTitle}</a>
          )}
        </BreadcrumbHexa.Item>
      ))}
      <BreadcrumbHexa.Item data-testid="breadcrumb-current-item" isCurrentPage>
        {currentPage}
      </BreadcrumbHexa.Item>
    </BreadcrumbHexa.Root>
  );
};

export default Breadcrumb;
