import { SkeletonLoaderWrapper } from 'components/skeleton/Skeleton.styles';
import { SkeletonProps } from 'components/types/Skeleton';
import { useApiContext } from 'context';

const Skeleton = ({
  id,
  children,
  variant,
  width,
  height,
  imageShape,
  ml,
  mr,
  mt,
  mb,
  addStyle,
}: SkeletonProps): React.ReactElement => {
  const { isLoading } = useApiContext();

  return (
    <>
      {isLoading ? (
        <SkeletonLoaderWrapper
          data-testid={id}
          variant={variant}
          width={width}
          height={height}
          imageShape={imageShape}
          ml={ml}
          mr={mr}
          mt={mt}
          mb={mb}
          addStyle={addStyle}
        />
      ) : (
        children
      )}
    </>
  );
};

export default Skeleton;
