import { SkeletonLoader } from '@hexa-ui/components';
import styled from 'styled-components';

export const SelectContainer = styled.div`
  & button {
    margin-top: 0px;
  }
`;

export const StyledSkeletonLoader = styled(SkeletonLoader)`
  border-radius: 34px;
`;
