import { FieldsMetaData, GROUPED_ATTRIBUTES_PATH, isEmpty } from '@components/GenericAttributes';
import * as yup from 'yup';

export default function useFormSchema(): yup.ObjectSchema<
  {
    attributes: unknown[];
  },
  FormData
> {
  const getValueSchema = (attribute: ClassAttribute) => {
    const metadata = FieldsMetaData[attribute.type];
    let schema = metadata?.schema;
    const isRequired = !!attribute.uom || attribute.isRequired;
    schema = applyRequired(schema, isRequired);
    return schema;
  };

  const getUomSchema = (attribute: ClassAttribute) => {
    const schema = yup.string();
    const isRequired = !!(attribute.type === 'MEASURE' && attribute.value);
    return applyRequired(schema, isRequired);
  };

  const applyRequired = (schema: yup.AnySchema, isRequired: boolean) =>
    isRequired
      ? schema.test('is-required-global', 'Components.REQUIRED_FIELD', (value) => !isEmpty(value))
      : schema;

  const createItemDetailsAttributeSchema = (value: ClassAttribute) => {
    const valueSchema = getValueSchema(value);
    const uomSchema = getUomSchema(value);

    return yup.object<ClassAttribute>().shape({
      attributeId: yup.string(),
      defaultKey: yup.string(),
      description: yup.string(),
      isRequired: yup.boolean(),
      key: yup.string(),
      name: yup.string(),
      sortOrder: yup.number(),
      units: yup.array().of(yup.string()),
      multipleSelection: yup.boolean(),
      allowedValues: yup.array().of(yup.string()),
      type: yup.string<ATTRIBUTETYPE>(),
      value: valueSchema,
      uom: uomSchema,
      groupedAttributes: yup
        .array()
        .of(
          yup.lazy((value) =>
            createItemDetailsAttributeSchema(value).omit([GROUPED_ATTRIBUTES_PATH])
          )
        ),
    });
  };

  const mainSchema = yup.object<FormData>().shape({
    attributes: yup.array().of(yup.lazy((value) => createItemDetailsAttributeSchema(value))),
  });
  return mainSchema;
}
