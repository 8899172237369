import TrackingService from './TrackingService';
import analytics from './generated/segment';

class TrackingServiceImp implements TrackingService {
  public getAnalytics(): typeof analytics {
    return analytics;
  }
}

export default TrackingServiceImp;
