import { Card, Input } from '@components';
import { ReactElement, useCallback, useMemo } from 'react';
import { FormatDateOptions, useIntl } from 'react-intl';

export interface ActivityHistoryProps {
  audit?: AuditResponse;
}

export function ActivityHistory({ audit }: Readonly<ActivityHistoryProps>): ReactElement {
  const { formatMessage, formatDate } = useIntl();

  const handleFormatDate = useCallback(
    (date: string) => {
      const dateOptions: FormatDateOptions = {
        month: 'short',
        day: '2-digit',
        year: 'numeric',
      };

      return formatDate(new Date(date), dateOptions);
    },
    [formatDate]
  );

  const texts = useMemo(() => {
    if (!audit || !Object.keys(audit).length) {
      const noneMessage = formatMessage({ id: 'Default.NONE' });
      return {
        created: noneMessage,
        updated: noneMessage,
      };
    }

    const { createdBy, updatedBy, createdDate, updatedDate } = audit;

    const created = formatMessage(
      { id: 'Components.ACTIVITY_HISTORY' },
      { username: createdBy, date: handleFormatDate(createdDate) }
    );
    const updated = formatMessage(
      { id: 'Components.ACTIVITY_HISTORY' },
      { username: updatedBy, date: handleFormatDate(updatedDate) }
    );
    return {
      created,
      updated,
    };
  }, [audit, formatMessage, handleFormatDate]);

  const IS_EDITING = false;

  return (
    <div style={{ width: '100%' }} data-testid="audition-log">
      <Card
        id="card-audition-log"
        border="medium"
        elevated="small"
        title={formatMessage({ id: 'Components.ACTIVITY_HISTORY_TITLE' })}
      >
        <Input
          id="input-audition-log-updated-by"
          label={formatMessage({ id: 'Components.ACTIVITY_HISTORY_TITLE_LAST_EDITION_BY' })}
          viewMode={!IS_EDITING}
          value={texts.updated}
          mt="1"
          mb="6"
        />
        <Input
          id="input-audition-log-created-by"
          label={formatMessage({ id: 'Components.ACTIVITY_HISTORY_TITLE_CREATED_BY' })}
          viewMode={!IS_EDITING}
          value={texts.created}
          mt="1"
        />
      </Card>
    </div>
  );
}
