import { Paragraph, StatusIndicator } from '@components';
import { Radio } from '@hexa-ui/components';
import { getDataTestForInputs, InputType } from '@utils/buildDataTestId';
import omit from 'lodash/omit';
import { Controller, useFormContext } from 'react-hook-form';
import { FieldContainer, FieldTitle, RadioContainer } from './EditableStatusField.styles';
import { EditableStatusFieldProps } from './EditableStatusField.types';

export const EditableStatusField = ({
  name,
  title,
  options,
  isEditing,
}: EditableStatusFieldProps): JSX.Element => {
  const { control, setValue, watch } = useFormContext();
  const currentValue = watch(name);

  const renderEditionMode = () => {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          const fieldWithoutRef = omit(field, 'ref');
          return (
            <Radio.Root
              {...fieldWithoutRef}
              onValueChange={(value) => setValue(name, value)}
              data-testid="status-input"
            >
              <RadioContainer>
                {Object.entries(options).map(([value, label]) => (
                  <Radio.Item
                    key={value}
                    id={value}
                    label={label}
                    value={value}
                    data-testid={getDataTestForInputs({
                      id: name,
                      inputType: ('radio-' + value.toLocaleLowerCase()) as InputType,
                      isEditing,
                    })}
                  />
                ))}
              </RadioContainer>
            </Radio.Root>
          );
        }}
      />
    );
  };

  const renderDisplayMode = () => {
    const testId = getDataTestForInputs({
      id: name,
      isEditing: false,
    });
    if (currentValue && options[currentValue]) {
      return <StatusIndicator status={currentValue} id={testId} text={options[currentValue]} />;
    } else {
      return <Paragraph data-testid={testId} text="-" />;
    }
  };

  return (
    <FieldContainer>
      <FieldTitle>
        <Paragraph data-testid="status-title" weight="semibold" text={title} />
      </FieldTitle>
      {isEditing ? renderEditionMode() : renderDisplayMode()}
    </FieldContainer>
  );
};
