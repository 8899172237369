import { Box } from '@components/box/Box';
import { styled } from '@hexa-ui/theme';

export const ListFieldsWrapper = styled(Box, {
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gridGap: '$6',
});

export const AttributeGroup = styled('div', {
  gridColumn: '1 / -1',
  margin: '$1 0',
  '& h5': {
    marginBottom: '12px',
    color: '$interfaceLabelSecondary',
  },
});

export const GroupSection = styled(ListFieldsWrapper, {
  borderLeft: '2px solid rgba(0, 0, 0, 0.1)',
  padding: '0 $4',
});
