import { Button, Dialog, Heading, Paragraph } from '@hexa-ui/components';
import { UseItemInformationFormReturn } from '@hooks/forms/useItemInformationForm/useItemInformationForm';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { useIntl } from 'react-intl';
import { ButtonsContainer, DialogContainer } from './SkuConfirmationModal.styles';

export interface SkuConfirmationModalRef {
  onOpen: () => void;
  onClose: () => void;
}

interface SkuConfirmationModalProps {
  onSubmit: UseItemInformationFormReturn['onSubmit'];
  isUpdating: boolean;
}

const SkuConfirmationModal = forwardRef<SkuConfirmationModalRef, SkuConfirmationModalProps>(
  function SkuConfirmationModal({ onSubmit, isUpdating }, ref) {
    const { formatMessage } = useIntl();
    const [isOpen, setIsOpen] = useState(false);

    const onOpen = () => setIsOpen(true);
    const onClose = () => setIsOpen(false);

    useImperativeHandle(
      ref,
      () => ({
        onOpen,
        onClose,
      }),
      []
    );

    return (
      <DialogContainer>
        <Dialog.Root
          open={isOpen}
          onClose={onClose}
          contentCss={{ maxWidth: '500px' }}
          actions={
            <ButtonsContainer>
              <Dialog.Close>
                <Button
                  data-testid="sku-modal-discard-button"
                  type="button"
                  size="medium"
                  variant="secondary"
                  disabled={isUpdating}
                  onClick={onClose}
                >
                  {formatMessage({ id: 'ItemDetailsPage.SKU_MODAL_CANCEL' })}
                </Button>
              </Dialog.Close>
              <Dialog.Close>
                <Button
                  data-testid="sku-modal-submit-button"
                  type="submit"
                  size="medium"
                  disabled={isUpdating}
                  isLoading={isUpdating}
                  onClick={() => onSubmit(onClose)}
                >
                  {formatMessage({ id: 'ItemDetailsPage.SKU_MODAL_UPDATE' })}
                </Button>
              </Dialog.Close>
            </ButtonsContainer>
          }
          title={
            <Heading size="H2">{formatMessage({ id: 'ItemDetailsPage.SKU_MODAL_TITLE' })}</Heading>
          }
        >
          <Paragraph>{formatMessage({ id: 'ItemDetailsPage.SKU_MODAL_DESCRIPTION' })}</Paragraph>
        </Dialog.Root>
      </DialogContainer>
    );
  }
);

export default SkuConfirmationModal;
