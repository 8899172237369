import { useEffect, useState } from 'react';

const useDebounce: UseDebounceType = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const [isDebounceLoading, setIsDebounceLoading] = useState(false);

  useEffect(() => {
    setIsDebounceLoading(true);
    const handler = setTimeout(() => {
      setDebouncedValue(value);
      setIsDebounceLoading(false);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value]);

  return [debouncedValue, isDebounceLoading];
};

export default useDebounce;
