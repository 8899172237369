import { SkeletonLoader } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';
import { generatedVariants } from 'components/utils/generateVariants';

export const SkeletonLoaderWrapper = styled(SkeletonLoader, {
  variants: {
    ...generatedVariants,
    addStyle: {
      border: {
        borderRadius: '$4 !important',
      },
    },
  },
});
