import {
  RecentActivityList,
  RecentActivityListProps,
} from '@components/RecentActivity/components/RecentActivityList/RecentActivityList';
import Heading from '@components/typography/Heading';
import Paragraph from '@components/typography/Paragraph';
import { Alert, Button, Dialog, LoadingDots } from '@hexa-ui/components';
import { useIsVisible } from '@hooks/useIsVisible/useIsVisible';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  ButtonWrapper,
  DialogBody,
  DialogContent,
  MoreItemsContainer,
} from './RecentActivityModal.styles';

export interface RecentActivityModalProps {
  data: RecentActivityListProps['data'];
  hasNext: boolean;
  isLoading: boolean;
  hasError: boolean;
  isOpen: boolean;
  handleClose: () => void;
  fetchNextPage: () => void;
}

type AlertType = 'error' | 'success';

export function RecentActivityModal({
  handleClose,
  fetchNextPage,
  hasError,
  hasNext,
  isOpen,
  data,
}: Readonly<RecentActivityModalProps>): React.ReactElement {
  const { formatMessage } = useIntl();

  const scrollRef = useRef<HTMLDivElement>(null);
  const { isVisible, ref } = useIsVisible();
  const [showSnackBar, setShowSnackBar] = useState<null | 'COPY' | 'ERROR'>(null);

  const handleCloseModal = () => {
    setShowSnackBar(null);
    handleClose();
  };

  useEffect(() => {
    if (isVisible && hasNext) {
      fetchNextPage();
    }
  }, [isVisible, hasNext, fetchNextPage]);

  const handleScrollToTop = () => {
    scrollRef.current?.scroll?.({ top: 0 });
  };

  const onCopySuccess = () => {
    setShowSnackBar('COPY');
    handleScrollToTop();
  };

  const onError = useCallback(() => {
    setShowSnackBar('ERROR');
    handleScrollToTop();
  }, []);

  useEffect(() => {
    if (hasError) onError();
  }, [hasError, onError]);

  const renderedSnackBar = useCallback(() => {
    const snackSucces = {
      message: formatMessage({ id: 'RecentActivity.COPY_MESSAGE_SUCCESS' }),
      type: 'success' as AlertType,
    };

    const snackError = {
      message: formatMessage({ id: 'RecentActivity.ERROR_MESSAGE' }),
      type: 'error' as AlertType,
    };
    const props = showSnackBar === 'COPY' ? snackSucces : snackError;

    return (
      <Alert
        {...props}
        showCloseButton
        onClose={() => setShowSnackBar(null)}
        css={{
          width: '100%',
        }}
      />
    );
  }, [showSnackBar, formatMessage]);

  return (
    <Dialog.Root
      open={isOpen}
      onClose={handleCloseModal}
      onInteractOutside={handleCloseModal}
      onEscapeKeyDown={handleCloseModal}
      contentCss={{ width: '863px' }}
      title={<Heading title={formatMessage({ id: 'RecentActivity.TITLE' })} size="H2" />}
      actions={
        <ButtonWrapper>
          <Dialog.Close>
            <Button size="medium" variant="primary" onClick={handleCloseModal}>
              Close
            </Button>
          </Dialog.Close>
        </ButtonWrapper>
      }
    >
      <DialogContent>
        <Paragraph text={formatMessage({ id: 'RecentActivity.MODAL_BODY' })} />
        {showSnackBar && renderedSnackBar()}
      </DialogContent>
      <DialogBody ref={scrollRef} data-testid="list-audit-log">
        <RecentActivityList data={data} isDetailsExpandable onCopySuccess={() => onCopySuccess()} />
        <MoreItemsContainer data-testid="more-items-container" hasNextPage={hasNext} ref={ref}>
          {hasNext && (
            <>
              <LoadingDots size="large" />
              <Paragraph text="Loading" ml="2" />
            </>
          )}
        </MoreItemsContainer>
      </DialogBody>
    </Dialog.Root>
  );
}
