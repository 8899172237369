import { styled } from '@hexa-ui/theme';

export const FieldContainer = styled('div', {
  minHeight: '64px',
  display: 'flex',
  flexDirection: 'column',
  gap: '$1',
  '& #status-display': {
    paddingTop: '2px',
  },
  '& [role="radiogroup"] > div > div': {
    margin: '$2 0',
    '& label': {
      paddingLeft: '$2',
    },
  },
});

export const RadioContainer = styled('div', {
  display: 'flex',
  gap: '24px',
});

export const FieldTitle = styled('div', {
  display: 'flex',
  gap: '10px',
});
