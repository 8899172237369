import { patchItemService } from '@services/items/ItemsService';
import { PatchItemServiceBody } from '@services/items/ItemsService.types';
import { useMutation } from '@tanstack/react-query';
import { TypeToast, useToast } from 'admin-portal-shared-services';
import { BASE_MANAGEMENT_BFF_PATH, URLS } from 'Api/enum';
import { useSWRConfig } from 'swr';

interface UsePatchItemParams {
  id: string;
  country: string;
  successMessage: string;
  failMessage: string;
}

export interface UsePatchItemResult {
  isLoading: boolean;
  updateItem: (body: PatchItemServiceBody) => Promise<void>;
}

export const usePatchItem = ({
  id,
  country,
  failMessage,
  successMessage,
}: UsePatchItemParams): UsePatchItemResult => {
  const { mutate } = useSWRConfig();
  const toast = useToast();

  const { mutateAsync: updateItem, isLoading } = useMutation({
    mutationFn: (body: PatchItemServiceBody) => patchItemService({ ...body, id, country }),
    onSuccess: async () => {
      await mutate(`${BASE_MANAGEMENT_BFF_PATH}${URLS.ITEMS_DETAILS}/${id}`);
      toast.notify({
        message: successMessage,
        type: TypeToast.SUCCESS,
      });
    },
    onError: () => {
      toast.notify({
        message: failMessage,
        type: TypeToast.ERROR,
      });
    },
  });

  return {
    updateItem,
    isLoading,
  };
};
