import { Info } from '@hexa-ui/icons';
import { styled } from '@hexa-ui/theme';

export const FieldTitle = styled('div', {
  display: 'flex',
  gap: '6px',
  alignItems: 'center',
  '& [data-testid="tooltip"]': {
    height: '16px',
  },
});

export const InputContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$1',
  width: '100%',
  variants: {
    hasError: {
      true: {
        '& > div:first-child > div': {
          outline: '1px solid $semanticErrorText',
        },
      },
    },
  },
});

export const FieldContainer = styled('div', {
  minHeight: '64px',
  display: 'flex',
  flexDirection: 'column',
  gap: '$1',
  '& > p': {
    minHeight: '40px',
    paddingTop: '2px',
  },
  '& input': {
    height: '40px',
  },
});

export const InfoIcon = styled(Info, {
  color: 'rgba(20, 20, 20, 0.56)',
});
