import { Paragraph } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';
import scrollStyle from 'utils/scrollStyle';

export const ScrollableDivContainer = styled('div', {
  maxHeight: '324px',
  padding: '5px 0 0 16px',
  overflowY: 'auto',
  overflowX: 'hidden',
  scrollMargin: '30px',
  scrollPadding: '30px',
  scrollbarGutter: 'stable',
  scrollbarWidth: '8px',
  scrollbarTrackColor: 'white',
  scrollbarColor: ' #dbdada white',
  scrollStyle,
});

export const LoadingContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: '6px',
});

export const EmptyContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: 'auto 0',
  gap: '16px',
});

export const SearchInputContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  padding: '16px 16px 0 16px',
  gap: '8px',
  width: '100%',
  '& > div': {
    height: '40px',
  },
});

export const SellerItemContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  margin: '10px 0',
});

export const StyledParagraph = styled(Paragraph, {
  maxWidth: '170px',
});
