import { Input } from '@components';

export const Loading = (): React.ReactElement => {
  return (
    <>
      {Array.from({ length: 6 }, (_, index) => (
        <Input id={String(index)} viewMode key={index} />
      ))}
    </>
  );
};
