/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

interface UseFileUploaderProps {
  handleFileUpload: () => Promise<string>;
  onUploadFinished?: (newFileUrl: string) => void | Promise<void>;
  onClear?: () => void;
  apiErrorMessageId?: string;
  requiredErrorMessageId?: string;
  clearErrorOnClear?: boolean;
  isUploading?: boolean;
  setIsUploading?: (isUploading: boolean) => void;
  externalError?: string | boolean;
}

export const useFileUploader = ({
  handleFileUpload,
  onUploadFinished,
  onClear,
  apiErrorMessageId = 'Default.ERROR_UPDATE_IMAGE',
  requiredErrorMessageId = 'Default.REQUIRED_FILE',
  clearErrorOnClear,
  setIsUploading: externalSetIsUploading,
  isUploading: externalIsUploading,
  externalError,
}: UseFileUploaderProps) => {
  const { formatMessage } = useIntl();
  const [internalIsUploading, setInternalIsUploading] = useState(false);
  const [error, setError] = useState<string | boolean>(externalError ?? false);

  const isUploading = externalIsUploading !== undefined ? externalIsUploading : internalIsUploading;
  const setIsUploading =
    externalSetIsUploading !== undefined ? externalSetIsUploading : setInternalIsUploading;

  const handleDropAccepted = useCallback(async () => {
    setError(false);
    setIsUploading(true);

    try {
      const result = await handleFileUpload();
      if (onUploadFinished) {
        await onUploadFinished(result);
      }
    } catch (_) {
      setError(formatMessage({ id: apiErrorMessageId }));
    } finally {
      setIsUploading(false);
    }
  }, [setIsUploading, handleFileUpload, onUploadFinished, formatMessage, apiErrorMessageId]);

  const handleClear = useCallback(() => {
    if (onClear) onClear();

    if (clearErrorOnClear) {
      return setError(false);
    }

    setError(formatMessage({ id: requiredErrorMessageId }));
  }, [clearErrorOnClear, formatMessage, onClear, requiredErrorMessageId]);

  useEffect(() => {
    if (externalError !== undefined) {
      setError(externalError);
    }
  }, [externalError]);

  return {
    handleDropAccepted,
    handleClear,
    error,
    isUploading,
  };
};
