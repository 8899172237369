import { styled } from '@hexa-ui/theme';
import Label from '../label/Label';
import Paragraph from '../typography/Paragraph';

const StyledParagraph = styled(Paragraph, {
  minHeight: '48px',
  alignItems: 'center',
  display: 'flex',
});

interface InputViewModeProps {
  label: string;
  value: string;
  id?: string;
  tooltipText?: string;
  tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right';
  colorType?: 'primary' | 'secondary' | 'disabled' | 'success' | 'error';
  mr?: SpaceTokens;
  mb?: SpaceTokens;
  mt?: SpaceTokens;
  ml?: SpaceTokens;
  className?: string;
}

function InputViewMode({
  id,
  label,
  tooltipText,
  value,
  tooltipPlacement,
  colorType,
  mb,
  ml,
  mr,
  mt,
  className = 'input',
}: InputViewModeProps): React.ReactElement {
  return (
    <div className={className}>
      <Label text={label} tooltipText={tooltipText} tooltipPlacement={tooltipPlacement} />
      <StyledParagraph
        text={value}
        colorType={colorType}
        mr={mr}
        mt={mt}
        mb={mb}
        ml={ml}
        data-testid={id}
      />
    </div>
  );
}

export default InputViewMode;
