export const ALL_COUNTRIES_LIST = ['BR', 'PY', 'ZA'];
export const DEFAULT_LANGUAGE = 'en-US';
export const LANGUAGE_BY_COUNTRY = {
  BR: 'pt-BR',
  PY: 'es-PY',
  ZA: 'en-US',
  KR: 'ko-KR',
};
export const MAX_SELLERS_SEARCH = 50;
export const MAX_SEARCH = 30;
export const MAX_CSV_FILE_SIZE_DEFAULT_IN_MB = 25;
export const MAX_ZIP_FILE_SIZE_DEFAULT_IN_MB = 100;
export const ONE_MEGABYTE_IN_BYTES = 1000000;
export const MAX_FILE_SIZE_PER_IMAGE = '1.5MB';
export const MAX_IMAGE_RESOLUTION = '600x600';
