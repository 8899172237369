import { Select } from '@hexa-ui/components';
import { SelectContainer, StyledSkeletonLoader } from './SelectorView.styles';

type Options = {
  id: string;
  name: string;
};

type Props = {
  options: Array<Options>;
  defaultValue: string;
  isLoading: boolean;
  disabled?: boolean;
  onChange: (value: string) => void;
  icon?: React.ReactElement;
  callback?: 'store' | 'country';
};

function SelectorView({
  options,
  defaultValue,
  isLoading,
  onChange,
  disabled,
  icon,
  callback,
}: Readonly<Props>): React.ReactElement {
  return (
    <SelectContainer>
      {isLoading ? (
        <StyledSkeletonLoader
          data-testid="selector-loader"
          variant="body"
          imageShape="circle"
          width="160px"
          height="34px"
        />
      ) : (
        <Select.Root
          defaultValue={defaultValue}
          size="small"
          width="160px"
          shape="pill"
          disabled={disabled}
          icon={icon}
          data-testid={`${callback}-select`}
          onChange={onChange}
          placeholder={`Select a ${callback}`}
        >
          <>
            {options.length === 0 ? (
              <Select.Option value={null}>No options</Select.Option>
            ) : (
              options.map((option) => (
                <Select.Option key={option.id} value={option.id}>
                  {option.name}
                </Select.Option>
              ))
            )}
          </>
        </Select.Root>
      )}
    </SelectContainer>
  );
}

export default SelectorView;
