const TOKEN_SIZES: SpaceTokens[] = ['0', '025', '05', '1', '2', '4', '6', '8'];

const MARGIN_ABBR_AND_CSS_PROP: { id: MarginAbbreviations; value: MarginCSSProperties }[] = [
  { id: 'mt', value: 'marginTop' },
  { id: 'mr', value: 'marginRight' },
  { id: 'mb', value: 'marginBottom' },
  { id: 'ml', value: 'marginLeft' },
];
const PADDING_ABBR_AND_CSS_PROP: { id: PaddingAbbreviations; value: PaddingCSSProperties }[] = [
  { id: 'pt', value: 'paddingTop' },
  { id: 'pr', value: 'paddingRight' },
  { id: 'pb', value: 'paddingBottom' },
  { id: 'pl', value: 'paddingLeft' },
];
const ALL_ABBR_AND_CSS_PROP = [...MARGIN_ABBR_AND_CSS_PROP, ...PADDING_ABBR_AND_CSS_PROP];

const generateVariantValues = (currentProperty: StyleCSSProperties): VariantValues =>
  TOKEN_SIZES.reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: {
        [currentProperty]: `$${curr}`,
      },
    }),
    {}
  ) as VariantValues;

// To understand better how this method works go to the tests
export const generatedVariants: Variants = ALL_ABBR_AND_CSS_PROP.reduce(
  (acc, curr) => ({
    ...acc,
    [curr.id]: generateVariantValues(curr.value),
  }),
  {}
) as Variants;
