import { Checkbox } from '@hexa-ui/components';
import { useIntl } from 'react-intl';
import { StatusEnum } from 'types/status/status.enum';
import { CheckboxContainer, StatusFilterTabContainer } from './StatusFilterTab.style';

interface StatusFilterTabProps {
  status: string;
  onStatusCheckedChange: (status: string) => void;
}

function StautsFilterTab({ status, onStatusCheckedChange }: Readonly<StatusFilterTabProps>) {
  const { formatMessage } = useIntl();
  return (
    <StatusFilterTabContainer>
      <CheckboxContainer>
        <Checkbox
          checked={status === StatusEnum.ENABLED}
          id="enabled-chkb"
          label={formatMessage({ id: 'StatusFilterTab.ENABLED' })}
          value={StatusEnum.ENABLED}
          onCheckedChange={() => onStatusCheckedChange(StatusEnum.ENABLED)}
        />
      </CheckboxContainer>
      <CheckboxContainer>
        <Checkbox
          checked={status === StatusEnum.DISABLED}
          id="disabled-chkb"
          label={formatMessage({ id: 'StatusFilterTab.DISABLED' })}
          value={StatusEnum.DISABLED}
          onCheckedChange={() => onStatusCheckedChange(StatusEnum.DISABLED)}
        />
      </CheckboxContainer>
    </StatusFilterTabContainer>
  );
}

export default StautsFilterTab;
