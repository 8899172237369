const permissionConstants = {
  PRODUCT_WRITE: 'CatalogAdmin.Product.Write',
  PRODUCT_LIST: 'CatalogAdmin.ProductList.Read',
  PRODUCT_READ: 'CatalogAdmin.Product.Read',
  PRODUCT_UPDATE: 'CatalogAdmin.Products.Update',
  CATALOG_COUNTRIES_ALL_SCOPE: 'CatalogAdmin.CountriesAll.Execute',
  CATALOG_VENDORS_ALL_SCOPE: 'CatalogAdmin.VendorsAll.Execute',
  ITEM_WRITE: 'CatalogAdmin.Item.Write',
  ITEM_READ: 'CatalogAdmin.Item.Read',
  CATEGORY_READ: 'CatalogAdmin.Categories.Read',
  CATEGORY_WRITE: 'CatalogAdmin.Categories.Write',
} as const;

export default permissionConstants;
