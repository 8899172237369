import { ListItem } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';
import { generatedVariants } from 'components/utils/generateVariants';

export const ListContainerWrapper = styled('div', {
  '&>ul': {
    listStyle: 'none',
  },
});

export const ListItemWrapper = styled(ListItem, {
  borderColor: '$neutral30',
  borderWidth: '$hairline',
  borderStyle: 'solid',
  borderRadius: '$4',
  overflow: 'hidden',
  '& > div': {
    padding: '$2',
  },
  variants: {
    ...generatedVariants,
  },
});
