import { styled } from '@hexa-ui/theme';

export const Container = styled('div', {
  position: 'absolute',
  top: '56px',
  left: '0',
  backgroundColor: '$neutral10',
});

export const Content = styled('div', {
  display: 'flex',
  height: 'calc(100vh - 56px)',
  width: '100vw',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',

  '& [class*="error-404"]': {
    width: '100%',
    maxWidth: '450px',
    height: 'auto',
    marginBottom: '$4',
  },
});
