import { styled } from '@hexa-ui/theme';

export const BadgeCounterWrapper = styled('div', {
  '& > div': {
    minWidth: '$space$6',
    display: 'flex',
  },
  '& p': {
    margin: '0 auto',
  },
  variants: {
    color: {
      yellow: {},
      blue: {
        '& > div': {
          backgroundColor: '$semanticInfoBasis',
        },
        '& p': {
          color: 'white',
        },
      },
    },
  },
});
