import { Paragraph } from '@hexa-ui/components';
import { Skeleton } from 'components';
import Icon from '../icon/Icon';
import { ErrorMessageContainer } from './ErrorMessage.styles';

const ErrorMessage = ({ text, id }: { text: string; id: string }): React.ReactElement => {
  return (
    <Skeleton
      id={`skeleton-${id}`}
      variant="body"
      imageShape="rectangle"
      height="16px"
      width="160px"
      mb="1"
    >
      <ErrorMessageContainer>
        <Icon size="tiny" name="AlertOctagon" color="semanticErrorText" />
        <Paragraph colortype="error" size="small">
          {text}
        </Paragraph>
      </ErrorMessageContainer>
    </Skeleton>
  );
};

export default ErrorMessage;
