import { IconButton as IconButtonHexa } from '@hexa-ui/components';
import { Icon } from 'components';
import { IconButtonProps } from 'components/types/IconButton';

const IconButton = ({
  id,
  name,
  type = 'button',
  variant = 'tertiary',
  onClick,
  size,
  elevation,
  loading,
  disabled,
  style,
  className,
}: IconButtonProps): React.ReactElement => {
  return (
    <IconButtonHexa
      type={type}
      style={style}
      id={id}
      data-testid={id}
      icon={() => <Icon name={name} />}
      variant={variant}
      onClick={onClick}
      size={size}
      elevation={elevation}
      loading={loading}
      disabled={disabled}
      className={className}
    />
  );
};

export default IconButton;
