import { Paragraph } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const LabelContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  height: '20px',
  paddingBottom: '4px',
});

export const OptionalParaghaph = styled(Paragraph, {
  marginLeft: '4px',
  fontFamily: 'Work Sans',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '20px',
});

export const GroupAttributeTitle = styled(Paragraph, {
  fontFamily: 'Barlow',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '20px !important',
});
