import { Table as TableHexa } from '@hexa-ui/components';
import { EmptyState, LoadingDots, Paragraph } from 'components';
import { InfiniteLoadingStatus, TableWrapper } from 'components/table/Table.styles';
import { TableProps } from 'components/types/Table';
import { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';

const Table = ({
  id,
  columns,
  data,
  pagination = false,
  fixedHeader = true,
  loading = false,
  tableHeight = 'auto',
  loadingData = false,
  selectable = { useCheckbox: false },
  emptyMessage,
  handleScroll,
  onRow,
}: TableProps): React.ReactElement => {
  const { formatMessage } = useIntl();
  const loadingText = formatMessage({ id: 'Default.LOADING' });
  const emptyStateDescription = formatMessage({ id: 'VariantPage.TABLE_NO_ITEMS' });
  const renderEmptyMessage = emptyMessage || (
    <EmptyState id="table__empty-state-items" description={emptyStateDescription} />
  );
  const scrollableRef = useRef(null);
  const scrollCalledRef = useRef(false);
  useEffect(() => {
    const scrollableDiv = scrollableRef.current;
    if (scrollableDiv) {
      const child = scrollableDiv.getElementsByTagName('div')[2];

      const handleScrolling = () => {
        const remainingScrollHeight = child.scrollHeight - child.clientHeight - child.scrollTop;
        if (!scrollCalledRef.current && remainingScrollHeight <= 200) {
          handleScroll();
          scrollCalledRef.current = true;
        } else if (remainingScrollHeight > 200) {
          scrollCalledRef.current = false;
        }
      };

      child.addEventListener('scroll', handleScrolling);

      return () => {
        child.removeEventListener('scroll', handleScrolling);
      };
    }
  }, [handleScroll]);

  return (
    <>
      <TableWrapper id={id} data-testid={id} ref={scrollableRef}>
        <TableHexa
          columns={columns}
          data={data}
          pagination={pagination}
          tableHeight={tableHeight}
          fixedHeader={fixedHeader}
          emptyMessage={renderEmptyMessage}
          loading={loading}
          selectable={{ useCheckbox: selectable.useCheckbox }}
          onRow={onRow}
        />
      </TableWrapper>

      {loadingData && (
        <InfiniteLoadingStatus data-testid="table__infinite-loading-status">
          <Paragraph text={loadingText} /> <LoadingDots />
        </InfiniteLoadingStatus>
      )}
    </>
  );
};

export default Table;
