import { HeadingProps } from 'components/types/Heading.d';
import { HeadingHexaWrapper, HeadingWrapper } from 'components/typography/Heading.styles';

const Heading = ({
  children,
  size,
  title,
  alignment,
  mt,
  mr,
  mb,
  ml,
  'data-testid': testId,
}: HeadingProps): React.ReactElement => {
  return (
    <HeadingWrapper data-testid={testId} mr={mr} mt={mt} mb={mb} ml={ml}>
      <HeadingHexaWrapper size={size} alignment={alignment}>
        {title}
      </HeadingHexaWrapper>

      {!!children && children}
    </HeadingWrapper>
  );
};

export default Heading;
