import {
  ATTRIBUTE_PATH,
  ControllerWrapper,
  FormData,
  GenericAttributesContent,
  GenericAttributesFields,
  handleFormatAttributes,
  handleSortOrder,
  Loading,
  NamePath,
} from '@components/GenericAttributes';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback } from 'react';
import { Resolver, useFieldArray, useForm, UseFormReturn, useWatch } from 'react-hook-form';
import useFormSchema from '../useFormSchema/useFormSchema';

interface UseDynamicFormReturn {
  render: (isLoading?: boolean) => React.ReactElement;
  form: UseFormReturn<FormData, unknown, undefined>;
}

export function useDynamicForm(
  isEditing: boolean,
  attributes: ClassAttribute[] = []
): UseDynamicFormReturn {
  const schema = useFormSchema();

  const sortedAttributes = handleSortOrder(attributes);
  const formattedAttributes = handleFormatAttributes(sortedAttributes);

  const form = useForm<FormData>({
    defaultValues: { attributes: formattedAttributes },
    values: { attributes: formattedAttributes },
    criteriaMode: 'all',
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema) as Resolver<FormData>,
  });

  const { fields } = useFieldArray({
    control: form.control,
    name: ATTRIBUTE_PATH,
  });

  const watchedValues = useWatch({
    name: ATTRIBUTE_PATH,
    defaultValue: fields,
    control: form.control,
  });

  const getRender = useCallback(
    (attributes: ClassAttribute[]) => {
      return attributes?.map((attribute, index) => {
        const props = {
          form,
          name: `${ATTRIBUTE_PATH}.${index}` as NamePath,
          attribute,
          isEditing,
        };

        return <ControllerWrapper {...props} key={attribute.attributeId} testId="attribute" />;
      });
    },
    [form, isEditing]
  );

  const renderComponents = useCallback(
    (isLoading = false) => (
      <GenericAttributesContent>
        <GenericAttributesFields>
          {isLoading ? <Loading /> : getRender(watchedValues)}
        </GenericAttributesFields>
      </GenericAttributesContent>
    ),
    [watchedValues, getRender]
  );

  return { render: renderComponents, form };
}
