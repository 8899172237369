import { styled } from '@hexa-ui/theme';

export const DialogContainer = styled('div', {
  '& [role="dialog"]': {
    overflow: 'hidden',
  },
  '& [class*="image-false"]': {
    height: 'auto',
    margin: '$6 0',
  },
  '& [class*="shouldAdjustPadding"]': {
    padding: '0 $20 0 $8',
  },
});

export const ButtonsContainer = styled('div', {
  display: 'flex',
  gap: '$2',
  justifyContent: 'flex-end',
});
