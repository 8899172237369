import useApi from '@hooks/useApi/useApi';
import Api, { Fetcher } from 'Api';
import { BASE_MANAGEMENT_BFF_PATH, URLS } from 'Api/enum';
import { useEffect, useState } from 'react';

export interface UseGetItemDetailsById {
  itemDetails: ItemDetailsResponse;
  isLoadingItemDetails: boolean;
  error: any;
}

export const useGetItemDetailsById = ({
  country,
  itemId,
}: {
  country: string;
  itemId: string;
}): UseGetItemDetailsById => {
  const { get } = new Fetcher(Api);
  const [url, setUrl] = useState<string>('');
  const [headers, setHeaders] = useState<EnhancedHeaders | undefined>();

  useEffect(() => {
    if (country && itemId) {
      setUrl(`${BASE_MANAGEMENT_BFF_PATH}${URLS.ITEMS_DETAILS}/${itemId}`);
      setHeaders({ country });
    }
  }, [country, itemId]);

  const { isLoading, data, error } = useApi<ItemDetailsResponse>(
    url,
    get({ throwOnError: true }),
    headers
  );

  return {
    itemDetails: data,
    isLoadingItemDetails: isLoading,
    error,
  };
};
