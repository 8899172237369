import { createFeatureToggleServiceV2, useFeatureToggleV2 } from 'admin-portal-shared-services';
import { createContext, useContext, useEffect, useMemo } from 'react';
import type {
  Features,
  FeatureToggleContextType,
  FeatureToggleProviderProps,
  UseFeatureToggleReturnType,
} from './FeatureToggleProvider.types';

const PROJECT_NAME = 'bees-hub-catalog-admin-products-mfe';

const FeatureToggleContext = createContext<FeatureToggleContextType>({
  useFeatureToggle: () => [undefined, true],
});

export function FeatureToggleProvider({
  children,
  optimizelyKey,
}: FeatureToggleProviderProps): React.ReactElement {
  useEffect(() => {
    createFeatureToggleServiceV2(PROJECT_NAME, optimizelyKey);
  }, [optimizelyKey]);

  function useFeatureToggle(featureName: Features): UseFeatureToggleReturnType {
    const feature = useFeatureToggleV2(featureName, PROJECT_NAME);

    return feature;
  }

  const value = useMemo(() => ({ useFeatureToggle }), []);

  return <FeatureToggleContext.Provider value={value}>{children}</FeatureToggleContext.Provider>;
}

export function useFeatureToggleProvider(): FeatureToggleContextType {
  const context = useContext(FeatureToggleContext);
  return context;
}
