import { createContext, ReactNode, useContext, useMemo, useState } from 'react';

const ApiContext = createContext<ApiData>({ isLoading: false });
ApiContext.displayName = 'ApiContext';

const useApiContext = (): ApiData => {
  return useContext(ApiContext);
};

const ApiProvider = ({ children }: { children: ReactNode }): React.ReactElement => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const value = useMemo(() => {
    return {
      isLoading,
      setIsLoading,
    };
  }, [isLoading]);

  return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
};

export { ApiContext, ApiProvider, useApiContext };
