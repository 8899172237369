import { useFeatureToggleProvider } from '@/providers/FeatureToggleProvider/FeatureToggleProvider';
import { Features } from '@/providers/FeatureToggleProvider/FeatureToggleProvider.types';
import EmptyImageActivity from '@assets/empty-recent-activity.svg';
import ErrorImageActivity from '@assets/error-recent-activity.svg';
import { ActivityHistory, Card, Paragraph } from '@components';
import { RecentActivityModal } from '@components/RecentActivity/components/RecentActivityModal/RecentActivityModal';
import { SkeletonLoaderWrapper } from '@components/skeleton/Skeleton.styles';
import { Button, Image } from '@hexa-ui/components';
import { History2 } from '@hexa-ui/icons';
import { ENTITY } from '@hooks/useAuditLog/useAuditLog.types';
import { useRecentActivityData } from '@hooks/useRecentActivityData/useRecentActivityData';
import { TypeToast, useToast } from 'admin-portal-shared-services';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { ButtonContainer, EmptyContainer } from './RecentActivity.styles';
import { RecentActivityItemContainer } from './components/RecentActivityItem/RecentActivityItem.styles';
import { RecentActivityList } from './components/RecentActivityList/RecentActivityList';

export interface RecentActivityProps {
  entity: ENTITY;
  entityId: string;
  country: string;
  auditDataV1?: AuditResponse;
  type?: 'section' | 'button';
}

export const RecentActivity = ({
  entity,
  entityId,
  country,
  auditDataV1,
  type = 'section',
}: RecentActivityProps): React.ReactElement => {
  const { useFeatureToggle } = useFeatureToggleProvider();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isAuditLogEnabled, isLoadingAuditLogFeatureToggle] = useFeatureToggle(
    Features.CAD_AUDITLOG_ENABLED
  );

  const { data, isLoading, hasError, fetchNextPage, hasNextPage } = useRecentActivityData({
    entity,
    entityId,
    country,
  });

  const { formatMessage } = useIntl();

  const toast = useToast();

  if (isLoadingAuditLogFeatureToggle) return null;

  if (!isAuditLogEnabled) {
    if (!auditDataV1) return null;
    return <ActivityHistory audit={auditDataV1} />;
  }

  const isEmpty = !isLoading && data.length === 0;

  const disabled = !isModalOpen && (isLoading || hasError || isEmpty);

  const texts = {
    title: formatMessage({ id: 'RecentActivity.TITLE' }),
    showAll: formatMessage({ id: 'RecentActivity.SHOW_ALL' }),
    showRecentActivity: formatMessage({ id: 'RecentActivity.SHOW_RECENT_ACTIVITY' }),
    copyMessageSuccess: formatMessage({ id: 'RecentActivity.COPY_MESSAGE_SUCCESS' }),
    emptyMessage: formatMessage({ id: 'RecentActivity.EMPTY_MESSAGE' }),
    errorMessage: formatMessage({ id: 'RecentActivity.ERROR_MESSAGE' }),
  };

  const onCopySuccess = () => {
    toast.notify({
      message: texts.copyMessageSuccess,
      type: TypeToast.SUCCESS,
    });
  };

  const renderLoading = () => (
    <RecentActivityItemContainer data-testid={'recent-activity-loading'}>
      {Array.from({ length: 3 }).map((_, index) => (
        <SkeletonLoaderWrapper key={`skeleton-${index}`} height="16px" />
      ))}
    </RecentActivityItemContainer>
  );

  const renderError = () => (
    <EmptyContainer data-testid="recent-activity-error">
      <Image src={ErrorImageActivity} alt={texts.errorMessage} />
      <Paragraph text={texts.errorMessage} alignment="center" />
    </EmptyContainer>
  );

  const renderEmpty = () => (
    <EmptyContainer data-testid="recent-activity-empty">
      <Image src={EmptyImageActivity} alt={texts.emptyMessage} />
      <Paragraph text={texts.emptyMessage} alignment="center" />
    </EmptyContainer>
  );

  const renderActivityList = () => (
    <RecentActivityList
      data-testid="recent-activity-list"
      data={data.slice(0, 3)}
      isDetailsExpandable={false}
      onCopySuccess={onCopySuccess}
    />
  );

  const renderSection = (Content: React.ReactNode) => (
    <Card id="recent-activity" border="medium" elevated="small" title={texts.title}>
      {Content}
      <ButtonContainer>
        <Button
          icon={History2}
          disabled={disabled}
          variant="secondary"
          leading
          onClick={handleOpenModal}
        >
          {texts.showAll}
        </Button>
      </ButtonContainer>
    </Card>
  );

  const renderButtonShowRecentActivities = () => (
    <Button
      icon={History2}
      disabled={disabled}
      variant="secondary"
      leading
      onClick={handleOpenModal}
    >
      {texts.showRecentActivity}
    </Button>
  );

  const renderModalContent = () => {
    if (isLoading && !isModalOpen) return renderLoading();
    if (hasError) return renderError();
    if (isEmpty) return renderEmpty();
    return renderActivityList();
  };

  const renderContent = () => {
    if (type === 'button') return renderButtonShowRecentActivities();
    return renderSection(renderModalContent());
  };

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <>
      <RecentActivityModal
        data={data}
        handleClose={handleCloseModal}
        isLoading={isLoading}
        hasError={hasError}
        fetchNextPage={fetchNextPage}
        isOpen={isModalOpen}
        hasNext={hasNextPage}
      />
      {renderContent()}
    </>
  );
};
