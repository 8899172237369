import { Grid, Heading } from '@hexa-ui/components';
import { StyledGridItemProps } from 'components/types/PageHeader';
import styled from 'styled-components';

const { Container, Item } = Grid;

export const StyledGridContainer = styled(Container)`
  width: 100%;
`;

export const BreadcrumbContainer = styled.div`
  margin-bottom: 24px;
`;

export const StyledGridItem = styled(Item)<StyledGridItemProps>`
  display: flex;
  padding: 0;
  justify-content: ${(props) => props.justify || 'flex-start'};
`;

export const StyledEndGridItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: column;
`;

export const StyledHeading = styled(Heading)`
  display: flex;
  justify-content: flex-start;
  font-family: 'Barlow', work-sans;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: #141414;
`;

export const StyledActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: auto;
`;

export const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const PageHeaderContainer = styled.div`
  margin-bottom: 16px;
`;
