const ptBR: MessageMap = {
  Default: {
    ENABLED: 'Habilitado',
    DISABLED: 'Desabilitado',
    NONE: 'Nenhum',
    LOADING: 'Carregando',
  },
  ProductListPage: {
    TITLE: 'Produtos',
    CREATE_BUTTON: 'Criar produto',
  },
  CreateProductPage: {
    TITLE: 'Criar produto',
  },
  ProductPage: {
    TITLE: 'Detalhes do produto',
    TITLE_INFORMATION: 'Informação do produto',
    TITLE_PRODUCT_TYPE: 'Tipo do Produto',
    TITLE_IMAGE: 'Imagem do produto',
    TITLE_VARIANTS: 'Variantes',
    TITLE_AUDITION_LOG: 'Log de audição do produto',
    LABEL_NAME: 'Nome',
    LABEL_VARIATION_SUFFIX: 'Variação de sufixo',
    LABEL_DESCRIPTION: 'Descrição do produto',
    LABEL_STATUS: 'Status',
    LABEL_MANUFACTURER: 'Fabricante',
    LABEL_BRAND: 'Marca',
    LABEL_GTIN: 'GTIN',
    LABEL_LAST_BY: 'Última edição feita por',
    LABEL_CREATED_BY: 'Criado por',
    ON: 'em',
  },
  VariantPage: {
    TITLE: 'Detalhes da variante',
    TITLE_INFORMATION: 'Informação da variante',
    TITLE_PRODUCT_TYPE: 'Tipo do Produto',
    TITLE_IMAGE: 'Imagem da variante',
    TITLE_ITEMS: 'Itens',
    TITLE_ATTRIBUTES: 'Atributos da variante',
    LABEL_NAME: 'Nome',
    LABEL_SKU: 'SKU',
    LABEL_STATUS: 'Status',
    LABEL_GTIN: 'GTIN',
    VARIANT: 'Variante',
    TABLE_NO_ITEMS: 'Ainda não há itens',
  },
  ImportFilePage: {
    IMPORT_BUTTON: 'Importar',
  },
  BulkImportTable: {
    ENTITY: {
      ITEMS: 'Items',
      PRODUCT: 'Product',
    },
    OPERATION: {
      POST: 'Creation',
      EDITION: 'Edition',
    },
    STATUS: {
      SUCCESS: 'Success',
      ERROR: 'Failed to download file',
    },
  },
  CategoryListPage: {
    TABLE: {
      SHOWING_N_CATEGORIES: 'Showing {count} categories',
      EDIT_CATEGORY_POSITIONS: 'Edit Category Positions',
      EMPTY_TABLE: 'There are no categories created',
      ERROR_MESSAGE: 'Error while loading the categories',
      CATEGORY_NAME: 'Category name',
      SUBCATEGORIES: 'Subcategories',
      PRODUCTS: 'Products',
      STATUS: 'Status',
      LEVEL: 'Level',
      ENABLED: 'Enabled',
      DISABLED: 'Disabled',
      SUBCATEGORIES_TOOLTIP: 'Column shows the number of subcategories inside each category.',
      PRODUCTS_TOOLTIP: 'Column shows the number of products inside each category.',
      STATUS_TOOLTIP: 'Defines the visibility of this category.',
    },
    TABS: {
      ERROR_LOADING_GROUPS: 'There was an issue loading the category groups.',
      NO_GROUPS_AVAILABLE: 'There are no category groups available.',
    },
  },
};

export default ptBR;
