import { Input } from '@components';
import { getDataTestForInputs } from '@utils/buildDataTestId';
import { useCallback, useEffect } from 'react';
import { useController } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Select } from '../../../Select/Select';
import { UOM_PATH } from '../../constants';
import { MeasureWrapper } from '../../GenericAttributes.styles';
import { GenericInputProps, NamePath } from '../../GenericAttributes.types';

const MeasureInput = ({
  form,
  name,
  attribute,
  isEditing,
  errorMessage,
  hasError,
  onChange,
  value,
  baseName,
  eventHandler,
  baseTestId,
}: GenericInputProps): React.ReactElement => {
  const { formatMessage } = useIntl();

  const uomPath = `${baseName}.${UOM_PATH}` as NamePath;

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    return onChange(e.currentTarget.value);
  };

  const { field, fieldState } = useController({
    control: form.control,
    name: uomPath,
  });

  const uomErrorMessage = fieldState.error?.message;

  useEffect(() => {
    form.trigger(uomPath);
    form.trigger(name);
  }, [value, form, uomPath, field.value, name]);

  const handleGetOptions = useCallback(() => {
    const options = attribute.units?.map((option) => ({ value: option, label: option })) ?? [];
    return [
      {
        label: formatMessage({ id: 'Components.SELECT_AN_OPTION' }),
        value: '',
      },
      ...options,
    ];
  }, [attribute, formatMessage]);

  return (
    <MeasureWrapper>
      <Input
        name={name}
        onChange={handleChange}
        value={value as string}
        label={attribute.name}
        required={attribute.isRequired}
        viewMode={!isEditing}
        errorText={errorMessage}
        hasError={hasError}
        width={'100%'}
        placeholder=""
        tooltipText={attribute.description}
        optionalText={formatMessage({ id: 'Components.OPTIONAL' })}
        onKeyDown={eventHandler}
        id={getDataTestForInputs({
          id: attribute.defaultKey,
          prefix: baseTestId,
          inputType: 'field',
          isEditing,
        })}
      />

      <Select
        selectProps={{
          ...field,
          width: '100%',
          placeholder: formatMessage({ id: 'Components.SELECT_AN_OPTION' }),
          value: String(field.value ?? ''),
          multiple: false,
          error: uomErrorMessage ? formatMessage({ id: fieldState.error?.message }) : '',
        }}
        options={handleGetOptions()}
        hide={!isEditing}
        id={getDataTestForInputs({
          id: attribute.defaultKey,
          prefix: baseTestId,
          inputType: 'selection',
          isEditing,
        })}
      />
    </MeasureWrapper>
  );
};

export { MeasureInput };
