import React, { createContext, useContext, useState, useMemo } from 'react';

interface AuditLogContext {
  isUpToDate: boolean;
  setIsUpToDate: (isUpToDate: boolean) => void;
}

interface AuditLogProviderProps {
  children: React.ReactNode;
}

const AuditLogContext = createContext<AuditLogContext>({
  isUpToDate: false,
  setIsUpToDate: () => undefined,
});

const AuditLogProvider = ({ children }: AuditLogProviderProps): JSX.Element => {
  const [isUpToDate, setIsUpToDate] = useState(false);

  const value = useMemo(() => ({ isUpToDate, setIsUpToDate }), [isUpToDate, setIsUpToDate]);

  return <AuditLogContext.Provider value={value}>{children}</AuditLogContext.Provider>;
};

const useAuditLogContext = (): AuditLogContext => {
  const context = useContext(AuditLogContext);
  return context;
};

export { AuditLogProvider, useAuditLogContext };
