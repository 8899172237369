import { Badge, BadgeCounterTypes } from '@hexa-ui/components';
import { BadgeCounterWrapper } from './Counter.styles';

type BadgeCounterProps = Omit<BadgeCounterTypes, 'color'> & {
  color?: 'yellow' | 'blue';
};

const BadgeCounter = ({ color = 'yellow', ...props }: BadgeCounterProps): React.ReactElement => {
  return (
    <BadgeCounterWrapper color={color}>
      <Badge.Counter {...props} />
    </BadgeCounterWrapper>
  );
};

export default BadgeCounter;
