import { ErrorMessage, Paragraph } from '@components';
import { ClipboardButton } from '@components/clipboardButton';
import { Input, Tooltip } from '@hexa-ui/components';
import { getDataTestForInputs } from '@utils/buildDataTestId';
import { Controller, useFormContext } from 'react-hook-form';
import { FieldContainer, FieldTitle, InfoIcon, InputContainer } from './EditableTextField.styles';
import { EditableTextFieldProps } from './EditableTextField.types';

export const EditableTextField = ({
  name,
  title,
  isEditing,
  tooltip,
  disabled,
  placeholder,
  showCopyButton,
}: EditableTextFieldProps): JSX.Element => {
  const { control, formState, watch } = useFormContext();
  const hasError = !!formState.errors[name];
  const currentValue = watch(name);

  const renderTitle = () => (
    <FieldTitle data-testid="field-title">
      <Paragraph weight="semibold" text={title} colorType={hasError ? 'error' : 'primary'} />
      {!isEditing && currentValue && tooltip && (
        <Tooltip placement="top" text={tooltip}>
          <InfoIcon size="medium" />
        </Tooltip>
      )}
    </FieldTitle>
  );

  const testId = getDataTestForInputs({
    id: name,
    inputType: 'field',
    isEditing,
  });

  const renderInputField = () => (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <InputContainer hasError={hasError}>
          <Input
            {...field}
            data-testid={testId}
            placeholder={placeholder}
            disabled={disabled}
            width="100%"
            sufix={
              showCopyButton &&
              ((<ClipboardButton textToCopy={currentValue} />) as unknown as string)
            }
            required
          />
          {hasError && <ErrorMessage id={`error-${name}`} text={error?.message} />}
        </InputContainer>
      )}
    />
  );

  const renderParagraph = () => <Paragraph data-testid={testId} text={currentValue || '-'} />;

  return (
    <FieldContainer>
      {renderTitle()}
      {isEditing ? renderInputField() : renderParagraph()}
    </FieldContainer>
  );
};
