import { BoxWrapper } from 'components/box/Box.styles';
import { BoxProps } from 'components/types/Box';

export const Box = ({
  id,
  ml,
  mt,
  mr,
  mb,
  children,
  className = 'box',
}: BoxProps): React.ReactElement => {
  return (
    <BoxWrapper id={id} data-testid={id} className={className} ml={ml} mt={mt} mr={mr} mb={mb}>
      {children}
    </BoxWrapper>
  );
};

export default Box;
