import { styled } from '@hexa-ui/theme';

export const ButtonWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
});

export const DialogContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  marginBottom: '24px',
});

export const DialogBody = styled('div', {
  overflow: 'scroll',
  maxHeight: '616px',
});

export const AuditList = styled('div', {});

export const MoreItemsContainer = styled('div', {
  display: 'flex',
  justifyContent: 'flex-start',
  padding: '$4',

  variants: {
    hasNextPage: {
      false: {
        height: 0,
        padding: 0,
      },
    },
  },
});
