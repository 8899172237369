const es419: MessageMap = {
  Default: {
    ENABLED: 'Activado',
    DISABLED: 'Desactivado',
    NONE: 'Ninguno',
    LOADING: 'Cargando',
  },
  ProductListPage: {
    TITLE: 'Productos',
    CREATE_BUTTON: 'Crear producto',
  },
  CreateProductPage: {
    TITLE: 'Crear producto',
  },
  ProductPage: {
    TITLE: 'Detalles de producto',
    TITLE_INFORMATION: 'Información del producto',
    TITLE_PRODUCT_TYPE: 'Tipo do Producto',
    TITLE_IMAGE: 'Imagen del producto',
    TITLE_VARIANTS: 'Cariantes',
    TITLE_AUDITION_LOG: 'Registro de audición del producto',
    LABEL_NAME: 'Nombre',
    LABEL_VARIATION_SUFFIX: 'Sufijo de variación',
    LABEL_DESCRIPTION: 'Descripción del Producto',
    LABEL_STATUS: 'Estado',
    LABEL_MANUFACTURER: 'Fabricante',
    LABEL_BRAND: 'Marca',
    LABEL_GTIN: 'GTIN',
    LABEL_LAST_BY: 'Última edición por',
    LABEL_CREATED_BY: 'Creado por',
    ON: 'en',
  },
  VariantPage: {
    TITLE: 'Detalles de la variante',
    TITLE_INFORMATION: 'Información de la variante',
    TITLE_PRODUCT_TYPE: 'Tipo do Producto',
    TITLE_IMAGE: 'Imagen del variante',
    TITLE_ITEMS: 'Ítems',
    TITLE_ATTRIBUTES: 'Atributos de la variante',
    LABEL_NAME: 'Nombre',
    LABEL_SKU: 'SKU',
    LABEL_STATUS: 'Estado',
    LABEL_GTIN: 'GTIN',
    VARIANT: 'Variante',
    TABLE_NO_ITEMS: 'Aún no hay elementos',
  },
  ImportFilePage: {
    IMPORT_BUTTON: 'Importar',
  },
  BulkImportTable: {
    ENTITY: {
      ITEMS: 'Items',
      PRODUCT: 'Product',
    },
    OPERATION: {
      POST: 'Creation',
      EDITION: 'Edition',
    },
    STATUS: {
      SUCCESS: 'Success',
      ERROR: 'Failed to download file',
    },
  },
  CategoryListPage: {
    TABLE: {
      SHOWING_N_CATEGORIES: 'Showing {count} categories',
      EDIT_CATEGORY_POSITIONS: 'Edit Category Positions',
      EMPTY_TABLE: 'There are no categories created',
      ERROR_MESSAGE: 'Error while loading the categories',
      CATEGORY_NAME: 'Category name',
      SUBCATEGORIES: 'Subcategories',
      PRODUCTS: 'Products',
      STATUS: 'Status',
      LEVEL: 'Level',
      ENABLED: 'Enabled',
      DISABLED: 'Disabled',
      SUBCATEGORIES_TOOLTIP: 'Column shows the number of subcategories inside each category.',
      PRODUCTS_TOOLTIP: 'Column shows the number of products inside each category.',
      STATUS_TOOLTIP: 'Defines the visibility of this category.',
    },
    TABS: {
      ERROR_LOADING_GROUPS: 'There was an issue loading the category groups.',
      NO_GROUPS_AVAILABLE: 'There are no category groups available.',
    },
  },
};

export default es419;
