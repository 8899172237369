import { SearchByEnum } from 'stores/itemsList/ItemsListState';
import type { GetTranslationKeyReturn } from './getSearchByTranslationKey.types';

const SEARCH_BY_TRANSLATION_KEYS: Record<SearchByEnum, GetTranslationKeyReturn> = {
  [SearchByEnum.SKUS]: 'SKUS',
  [SearchByEnum.GTINS]: 'GTINS',
  [SearchByEnum.VENDOR_ITEM_IDS]: 'VENDOR_ITEM_IDS',
};

export function getSearchByTranslationKey(searchByOption: SearchByEnum): GetTranslationKeyReturn {
  const translationkey = SEARCH_BY_TRANSLATION_KEYS[searchByOption];

  return translationkey;
}
