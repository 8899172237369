const scrollStyle = ` 
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #dbdada;
    border-radius: 5px;
    border: 3px solid white;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-button {
    background-color: transparent;
    border-radius: 5px;
    height: 5px;
  }`;

export default scrollStyle;
