import {
  Breadcrumb,
  CountrySelector,
  Error404,
  GridItem,
  PageHeader,
  PageHeaderContainer,
  RecentActivity,
} from '@components';
import { useGetItemDetailsById } from '@hooks/query/useGetItemDetailsById/useGetItemDetailsById';

import useTrackingService from 'hooks/useTrackingService/useTrackingService';
import { Details, DetailsColumn, PageContainer } from 'pages/pages.styles';
import { ReactElement, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { BASENAME, RouterParams, ROUTES } from 'Router';
import {
  getPageName,
  getPreviousPageName,
  useRouterContext,
} from '../../providers/RouterProvider/RouterProvider';
import { ItemInformation } from './components/ItemInformation/ItemInformation';
import { ItemType } from './components/ItemType/ItemType';
import { ProductVariantInformation } from './components/ProductVariantInformation/ProductVariantInformation';

function ItemDetailsPage(): ReactElement {
  const { formatMessage } = useIntl();
  const location = useLocation();
  const analytics = useTrackingService().getAnalytics();
  const routerContext = useRouterContext();

  const variantId = location.state?.variantId;
  const productId = location.state?.productId;

  const { country, itemId } = useParams<RouterParams>();

  const navigate = useNavigate();

  const { itemDetails, isLoadingItemDetails, error } = useGetItemDetailsById({ country, itemId });

  const pageProductListPath = formatMessage({ id: 'ProductListPage.TITLE' });
  const pageProductPath = formatMessage({ id: 'ProductPage.TITLE' });
  const pageVariantPath = formatMessage({ id: 'VariantPage.TITLE' });
  const pageItemsPath = formatMessage({ id: 'VariantPage.TITLE_ITEMS' });

  const breadCrumbPathDefault = [
    {
      linkTitle: pageItemsPath,
      linkKey: '1',
      linkUrl: `/${BASENAME}${ROUTES.ITEMS_LIST}`,
    },
  ];

  const breadCrumbVariantPath = [
    {
      linkTitle: pageProductListPath,
      linkKey: '1',
      linkUrl: `/${BASENAME}${ROUTES.PRODUCTS_LIST}`,
    },
    {
      linkTitle: pageProductPath,
      linkKey: '2',
      linkUrl: `/${BASENAME}${ROUTES.PRODUCT_DETAILS}/${productId}/${country}`,
    },
    {
      linkTitle: pageVariantPath,
      linkKey: '3',
      linkUrl: `/${BASENAME}${ROUTES.PRODUCT_DETAILS}/${productId}${ROUTES.VARIANT_DETAILS}/${variantId}/${country}`,
    },
  ];

  const breadCrumbPath = variantId && productId ? breadCrumbVariantPath : breadCrumbPathDefault;

  const previousPath = breadCrumbPath[breadCrumbPath.length - 1].linkUrl;

  useEffect(() => {
    if (!country) return;
    analytics.itemDetailsPageViewed({
      country,
      page_name: getPageName(routerContext.to),
      previous_page_name: getPreviousPageName(routerContext.from),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  if (error) {
    return <Error404 onClick={() => navigate(`/${BASENAME}${ROUTES.PRODUCTS_LIST}`)} />;
  }

  return (
    <PageContainer data-testid="item-details-page">
      <PageHeaderContainer>
        <PageHeader
          title={itemDetails?.sku}
          breadcrumb={
            <Breadcrumb
              currentPage={formatMessage({ id: 'ItemDetailsPage.TITLE' })}
              history={breadCrumbPath}
            />
          }
          prevPageUrl={previousPath}
        >
          <CountrySelector disabled />
        </PageHeader>
      </PageHeaderContainer>

      <Details type="fluid">
        <DetailsColumn type="left">
          <GridItem>
            <ItemInformation data={itemDetails} isLoading={isLoadingItemDetails} />
          </GridItem>
          <GridItem>
            <ItemType data={itemDetails} isLoading={isLoadingItemDetails} />
          </GridItem>
        </DetailsColumn>
        <DetailsColumn type="right">
          <GridItem>
            <ProductVariantInformation itemDetails={itemDetails} />
          </GridItem>
          <GridItem data-testid="item-page__grid-item-recent-activity">
            <RecentActivity
              entity="ITEMS"
              entityId={itemDetails?.id}
              country={country}
              auditDataV1={itemDetails?.audit}
            />
          </GridItem>
        </DetailsColumn>
      </Details>
    </PageContainer>
  );
}

export default ItemDetailsPage;
