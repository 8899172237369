import { Divider } from '@hexa-ui/components';
import {
  RecentActivityItem,
  RecentActivityItemProps,
} from '../RecentActivityItem/RecentActivityItem';

export interface RecentActivityListProps {
  data: Omit<RecentActivityItemProps, 'isDetailsExpandable'>[];
  isDetailsExpandable?: boolean;
  onCopySuccess?: () => void;
  onCopyError?: () => void;
}

export const RecentActivityList = ({
  data,
  isDetailsExpandable,
  onCopySuccess,
  onCopyError,
}: RecentActivityListProps) => {
  return (
    <div data-testid="recent-activity-list">
      {data.map((activityItem, index) => (
        <div key={index}>
          <RecentActivityItem
            {...activityItem}
            isDetailsExpandable={isDetailsExpandable}
            onCopySuccess={onCopySuccess}
            onCopyError={onCopyError}
          />
          {index < data.length - 1 && (
            <Divider data-testid="recent-activity-divider" css={{ margin: '16px 0', gap: '8px' }} />
          )}
        </div>
      ))}
    </div>
  );
};
