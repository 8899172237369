import { styled } from '@hexa-ui/theme';
import { Card } from '@hexa-ui/components';
import { generatedVariants } from '../utils/generateVariants';

export const CardWrapper = styled(Card, {
  width: '100%',

  variants: {
    ...generatedVariants,
  },
});

export const CardHeader = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
});
