import { styled } from '@hexa-ui/theme';

export const LabelWrapper = styled('label', {
  $$customColor: '#141414',
  fontWeight: '$semibold',
  fontSize: '$2',
  fontFamily: '$paragraph',
  color: '$$customColor',
  lineHeight: '$3',
});

export const LabelTextContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '0.25rem',
});

export const TooltipContainer = styled('div', {
  '& > div': {
    paddingTop: '3px',
  },
});
