import { styled } from '@hexa-ui/theme';

export const GenericAttributesContent = styled('div', {
  position: 'relative',
});

export const GenericAttributesFields = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat(2, minmax(230px, 1fr))',
  gap: '24px',
  alignItems: 'end',
  variants: {
    variant: {
      grouped: {
        borderLeft: '2px solid rgba(20, 20, 20, 0.12)',
        '& > :nth-of-type(odd)': {
          paddingLeft: '14px',
        },
      },
    },
  },
});

export const MeasureWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
  gap: '16px',
  width: '100%',
});

export const GroupWrapper = styled('div', {
  gridColumn: 'span 2',
  '& h5': {
    color: '$interfaceLabelSecondary',
  },
});
