import { Heading, Icon, Paragraph } from 'components';
import { CardHeader, CardWrapper } from 'components/card/Card.styles';
import { CardProps } from 'components/types/Card.d';

const Card = ({
  id,
  border,
  elevated,
  children,
  title,
  headerAction,
  subTitle,
  description,
  pl = '6',
  pr = '6',
  pt = '6',
  pb = '6',
}: CardProps): React.ReactElement => {
  return (
    <CardWrapper
      id={id}
      data-testid={id}
      border={border}
      elevated={elevated}
      pl={pl}
      pr={pr}
      pt={pt}
      pb={pb}
    >
      <CardHeader>
        {!!title && (
          <Heading title={title} size="H3" mb="6">
            {!!subTitle && (
              <>
                <Icon name="BookOpen" size="medium" color="interfaceLabelDisabled" />
                <Paragraph colorType="disabled" text={subTitle} ml="1" />
              </>
            )}
          </Heading>
        )}

        {headerAction}
      </CardHeader>
      {description && <Heading size="H4" title={description} mb="6" />}
      {children}
    </CardWrapper>
  );
};

export default Card;
