import { styled } from '@hexa-ui/theme';

export const EmptyContainer = styled('div', {
  display: 'grid',
  justifyItems: 'center',
});

export const ButtonContainer = styled('div', {
  marginTop: '16px',
  marginLeft: 'auto',
});
