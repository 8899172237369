import { useFeatureToggleProvider } from '@/providers/FeatureToggleProvider/FeatureToggleProvider';
import { Features } from '@/providers/FeatureToggleProvider/FeatureToggleProvider.types';
import { useCryptoHelpers } from '@hooks/useCryptoHelpers/useCryptoHelpers';
import {
  useAuthenticationService,
  useLogService,
  useAuditLog as useOriginalAuditLog,
} from 'admin-portal-shared-services';
import { useMemo } from 'react';
import { APP_ID, MAX_RETRIES } from './constants';
import { RegisterAuditLogProps } from './useAuditLog.types';
import { useAuditLogContext } from '@/providers/AuditLogProvider/AuditLogProvider';

type GetBaseMetadataProps = Record<string, string>;

export function useRegisterAuditLog(): (props: RegisterAuditLogProps) => Promise<void> {
  const auditLog = useOriginalAuditLog(APP_ID);
  const authService = useAuthenticationService();
  const log = useLogService();
  const { encryptAES } = useCryptoHelpers();
  const { useFeatureToggle } = useFeatureToggleProvider();
  const { setIsUpToDate } = useAuditLogContext();

  const [isAuditLogEnabled, isLoadingFeatureToggle] = useFeatureToggle(
    Features.CAD_AUDITLOG_ENABLED
  );
  const canAuditLog = useMemo(
    () => isAuditLogEnabled && !isLoadingFeatureToggle,
    [isLoadingFeatureToggle, isAuditLogEnabled]
  );

  const getBaseMetadata = (
    allMetadata: RegisterAuditLogProps['metadata']
  ): GetBaseMetadataProps | undefined => {
    try {
      const fullName = authService.getUserFullNameB2C();
      const userId = authService.parseJwt()?.sub;
      const userName = encryptAES(
        authService.parseJwt()?.name ?? `${fullName.given_name} ${fullName.family_name}`
      );

      const metadata = Object.entries(allMetadata).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: typeof value === 'string' ? value : JSON.stringify(value),
        }),
        {}
      );

      return {
        ...metadata,
        userName,
        userId,
      };
    } catch (error) {
      log.error(error);
      return undefined;
    }
  };

  const registerAuditLog = async ({ metadata, ...props }: RegisterAuditLogProps) => {
    const formattedMetadata = getBaseMetadata(metadata);
    if (canAuditLog && !!formattedMetadata) {
      const attemptLog = async (attempt: number) => {
        try {
          await auditLog({ ...props, metadata: formattedMetadata });
          setIsUpToDate(false);
        } catch (error) {
          if (attempt < MAX_RETRIES) {
            log.error(
              `Failed to log audit - Attempt ${
                attempt + 1
              }/${MAX_RETRIES} | Props: ${JSON.stringify({
                ...props,
                metadata: formattedMetadata,
              })}`
            );
            attemptLog(attempt + 1);
          }
        }
      };

      attemptLog(0);
    }
  };

  return registerAuditLog;
}
