const koKR: MessageMap = {
  Default: {
    ENABLED: '활성화됨',
    DISABLED: '비활성화됨',
    NONE: '없음',
    LOADING: '로드 중',
  },
  ProductListPage: {
    TITLE: '제품',
    CREATE_BUTTON: '제품 만들기',
  },
  CreateProductPage: {
    TITLE: '제품 만들기',
  },
  ProductPage: {
    TITLE: '제품 세부 정보',
    TITLE_INFORMATION: '제품 정보',
    TITLE_PRODUCT_TYPE: '상품 유형',
    TITLE_IMAGE: '제품 이미지',
    TITLE_VARIANTS: '변형',
    TITLE_AUDITION_LOG: '제품 오디션 로그',
    LABEL_NAME: '이름',
    LABEL_VARIATION_SUFFIX: '변형 접미사',
    LABEL_DESCRIPTION: '제품 설명',
    LABEL_STATUS: '상태',
    LABEL_MANUFACTURER: '제조사',
    LABEL_BRAND: '상표',
    LABEL_GTIN: 'GTIN',
    LABEL_LAST_BY: '마지막 판',
    LABEL_CREATED_BY: '작성자',
    ON: '',
  },
  VariantPage: {
    TITLE: '옵션 세부정보',
    TITLE_INFORMATION: '변형 정보',
    TITLE_PRODUCT_TYPE: '상품 유형',
    TITLE_IMAGE: '변형 이미지',
    TITLE_ITEMS: '항목',
    TITLE_ATTRIBUTES: '변형 속성',
    LABEL_NAME: '이름',
    LABEL_SKU: 'SKU',
    LABEL_STATUS: '상태',
    LABEL_GTIN: 'GTIN',
    VARIANT: '변종',
    TABLE_NO_ITEMS: '아직 항목 없음',
  },
  ImportFilePage: {
    IMPORT_BUTTON: '수입',
  },
  BulkImportTable: {
    ENTITY: {
      ITEMS: 'Items',
      PRODUCT: 'Product',
    },
    OPERATION: {
      POST: 'Creation',
      EDITION: 'Edition',
    },
    STATUS: {
      SUCCESS: 'Success',
      ERROR: 'Failed to download file',
    },
  },
  CategoryListPage: {
    TABLE: {
      SHOWING_N_CATEGORIES: 'Showing {count} categories',
      EDIT_CATEGORY_POSITIONS: 'Edit Category Positions',
      EMPTY_TABLE: 'There are no categories created',
      ERROR_MESSAGE: 'Error while loading the categories',
      CATEGORY_NAME: 'Category name',
      SUBCATEGORIES: 'Subcategories',
      PRODUCTS: 'Products',
      STATUS: 'Status',
      LEVEL: 'Level',
      ENABLED: 'Enabled',
      DISABLED: 'Disabled',
      SUBCATEGORIES_TOOLTIP: 'Column shows the number of subcategories inside each category.',
      PRODUCTS_TOOLTIP: 'Column shows the number of products inside each category.',
      STATUS_TOOLTIP: 'Defines the visibility of this category.',
    },
    TABS: {
      ERROR_LOADING_GROUPS: 'There was an issue loading the category groups.',
      NO_GROUPS_AVAILABLE: 'There are no category groups available.',
    },
  },
};

export default koKR;
