import { createStore } from 'effector';
import {
  resetCountryStore,
  setCountryOptions,
  setIsLoadingCountryOptions,
  setSelectedCountry,
} from './CountryEvents';
import CountryState from './CountryState';

const initialState: CountryState = {
  selectedCountry: null,
  countryOptions: [],
  isLoading: true,
};

const countryStore = createStore(initialState)
  .on(setIsLoadingCountryOptions, (state, isLoading) => ({
    ...state,
    isLoading,
  }))
  .on(setSelectedCountry, (state, selectedCountry) => ({
    ...state,
    selectedCountry,
  }))
  .on(setCountryOptions, (state, countryOptions) => ({
    ...state,
    countryOptions,
  }))
  .reset(resetCountryStore);

export default countryStore;
