import { Grid } from '@hexa-ui/components';
import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  * {  
    margin: 0;  
    padding: 0;  
    box-sizing: border-box;  
  }   
`;

export const CustomGridContainer = styled(Grid.Container)`
  display: block;
  height: calc(100vh - 56px);
  @media (max-width: 900px) {
    height: calc(100vh - 112px);
  }
`;
