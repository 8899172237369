import { styled } from '@hexa-ui/theme';
import { Box, GridContainer, Input } from '../components';

export const PageContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  flex: '1',
  paddingTop: '$8',
  paddingBottom: '$8',
});

Input.toString = () => '.input';
Box.toString = () => '.page__box-information';
export const DetailsColumn = styled(GridContainer, {
  flexDirection: 'column',
  flex: 2.3,
  rowGap: '$4',
  margin: '0 !important',

  [`& ${Input}`]: {
    flex: '1',
  },
  [`& ${Box}`]: {
    display: 'flex',
  },

  variants: {
    type: {
      left: {
        flex: 2.3,
      },
      right: {
        flex: 1,
      },
    },
  },
});

export const Details = styled(GridContainer, {
  rowGap: '$4',
  columnGap: '$4',
  maxWidth: '1920px',

  '&&': {
    margin: '0 auto',
  },
});

export const ImageContent = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  borderStyle: 'solid',
  borderColor: '$neutral30',
  borderWidth: '$hairline',
  borderRadius: '$4',
  padding: '$1',
});
