import { Skeleton } from 'components';
import { ParagraphWrapper } from 'components/typography/Paragraph.styles';

const Paragraph = ({
  text,
  size,
  colorType,
  alignment,
  weight,
  ml,
  mr,
  mt,
  mb,
  'data-testid': testId,
}: ParagraphProp): React.ReactElement => {
  return (
    <Skeleton
      id="skeleton-paragraph"
      width="120px"
      height="16px"
      imageShape="rectangle"
      variant="body"
      mb={mb}
    >
      <ParagraphWrapper
        size={size}
        alignment={alignment}
        colortype={colorType}
        weight={weight}
        mr={mr}
        mt={mt}
        mb={mb}
        ml={ml}
        data-testid={testId}
      >
        {text}
      </ParagraphWrapper>
    </Skeleton>
  );
};

export default Paragraph;
