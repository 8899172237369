import { RouterParams } from '@/Router';
import GenericAttributeForm from '@components/GenericAttributeForm/GenericAttributeForm';
import { usePatchItem } from '@hooks/query/usePatchItem/usePatchItem';
import { useRegisterAuditLog } from '@hooks/useAuditLog';
import { AttributeToPatch } from '@services/common.types';
import { generateAttributesAuditLog } from '@utils/auditLog/auditLog';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import useTrackingService from '../../../../hooks/useTrackingService/useTrackingService';
import {
  getPageName,
  getPreviousPageName,
  useRouterContext,
} from '../../../../providers/RouterProvider/RouterProvider';

export interface ItemTypeProps {
  data: ItemDetailsResponse;
  isLoading: boolean;
}
export const ItemType = ({ data, isLoading }: ItemTypeProps): React.ReactElement => {
  const { formatMessage } = useIntl();

  const { country, itemId } = useParams<RouterParams>();
  const { updateItem, isLoading: isUpdating } = usePatchItem({
    country,
    id: itemId,
    successMessage: formatMessage({ id: 'ItemDetailsPage.SUCCESS_UPDATE_ITEM_TYPE' }),
    failMessage: formatMessage({ id: 'ItemDetailsPage.ERROR_UPDATE_ITEM_TYPE' }),
  });

  const analytics = useTrackingService().getAnalytics();
  const routerContext = useRouterContext();
  const registerAuditLog = useRegisterAuditLog();

  const registerAuditLogAttributes = (newFields: AttributeToPatch[]) => {
    registerAuditLog({
      country,
      entity: 'ITEMS',
      operation: 'UPDATE',
      entityId: data.id,
      metadata: {
        action: 'UPDATE_ATTRIBUTES',
        itemId: data.id,
        fields: generateAttributesAuditLog(data.attributes, newFields),
      },
    });
  };

  const startEditingEvent = () => {
    analytics.itemTypeEditionStarted({
      button_name: 'Edit',
      country: country,
      item_status: data.status,
      page_name: getPageName(routerContext.to),
      sku: data.sku as unknown as number,
      previous_page_name: getPreviousPageName(routerContext.from),
      product_name: data.product.name,
      seller_id: data.vendorId as unknown as number,
      seller_name: data.vendorName,
      variant_gtin: data.variant?.gtin as unknown as number,
      item_type_attributes: data.attributes as unknown as string,
    });
  };

  const itemTypeDiscardedEvent = () => {
    analytics.itemTypeEditionDiscarded({
      button_name: 'Cancel',
      country,
      page_name: getPageName(routerContext.to),
      previous_page_name: getPreviousPageName(routerContext.from),
    });
  };

  const itemTypeSubmittedEvent = () => {
    analytics.itemTypeEditionSubmitted({
      button_name: 'Update',
      country,
      item_status: data.status,
      page_name: getPageName(routerContext.to),
      sku: data.sku as unknown as number,
      previous_page_name: getPreviousPageName(routerContext.from),
      product_name: data.product.name,
      seller_id: data.vendorId as unknown as number,
      seller_name: data.vendorName,
      variant_gtin: data.variant?.gtin as unknown as number,
    });
  };

  const sectionTexts = {
    title: formatMessage({ id: 'ItemDetailsPage.ITEM_TYPE' }),
    description: formatMessage({ id: 'ItemDetailsPage.ITEM_DETAILS' }),
  };

  const handleSubmit = async ({ attributes }: { attributes: AttributeToPatch[] }) => {
    await updateItem({ attributes });
    registerAuditLogAttributes(attributes);
  };

  return (
    <GenericAttributeForm
      id="item-details-page-item-type"
      attributes={data?.attributes}
      description={sectionTexts.description}
      isEditEnabled
      isLoading={isLoading}
      isUpdating={isUpdating}
      title={sectionTexts.title}
      onSubmit={handleSubmit}
      subTitle={data?.class?.name}
      onEditCallback={startEditingEvent}
      onCancelCallback={itemTypeDiscardedEvent}
      onSaveCallback={itemTypeSubmittedEvent}
    />
  );
};
