import { styled } from '@hexa-ui/theme';
import { Heading } from '@hexa-ui/components';
import { generatedVariants } from 'components/utils/generateVariants';

export const HeadingWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',

  variants: {
    ...generatedVariants,
  },
});

export const HeadingHexaWrapper = styled(Heading, {
  marginRight: '$2',
});
