export const getDataTestId = (prefix: string, id: string, suffix?: string) => {
  const normalizeString = (string: string) => {
    return string
      ?.replace(/[^a-zA-Z0-9\s]/g, '')
      .replace(/\s+/g, '-')
      .toLowerCase();
  };
  const dataTestId = `${prefix}-${normalizeString(id)}`;
  if (suffix) {
    return `${dataTestId}-${suffix}`;
  }
  return dataTestId;
};

export type InputType =
  | 'field'
  | 'selection'
  | 'date-picker-input'
  | 'radio-enabled'
  | 'radio-disabled';
interface GetDataTestIdForInputsProps {
  id: string;
  prefix?: string;
  inputType?: InputType;
  isEditing?: boolean;
}

export const getDataTestForInputs = ({
  id,
  prefix = 'attribute',
  inputType,
  isEditing,
}: GetDataTestIdForInputsProps): string => {
  return getDataTestId(prefix, id, isEditing ? inputType : 'text');
};
