export class LocalStorageStores {
  static readonly LOCAL_STORAGE_SELECTED_STORE_KEY = 'catalogAdmin-selectedStore';

  static saveStore(store: Store): void {
    if (!store) return;

    localStorage.setItem(this.LOCAL_STORAGE_SELECTED_STORE_KEY, JSON.stringify(store));
  }

  static getStore(): Store {
    const store = localStorage.getItem(this.LOCAL_STORAGE_SELECTED_STORE_KEY);

    return store ? JSON.parse(store) : {};
  }

  static removeStore(): void {
    localStorage.removeItem(this.LOCAL_STORAGE_SELECTED_STORE_KEY);
  }
}
