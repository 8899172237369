const transformSellersResponse = (response: any): SellersResponse => {
  return {
    vendors: response.content.map((seller: any) => ({
      vendorId: seller.vendorId,
      name: seller.displayName,
    })),
    pagination: response.pagination,
  };
};

export default transformSellersResponse;
