import { Button, ButtonProps } from '@hexa-ui/components';
import { Edit2, Plus, Upload } from '@hexa-ui/icons';

type CreateButtonProps = Omit<ButtonProps, 'icon'> & {
  children?: React.ReactNode;
  'data-testid'?: string;
  icon?: 'plus' | 'upload' | 'edit';
};

const iconMap = {
  plus: () => <Plus size="medium" />,
  upload: () => <Upload size="medium" />,
  edit: () => <Edit2 size="medium" />,
};

function CreateButton({
  children,
  onClick,
  'data-testid': testId,
  icon = 'plus',
  ...buttonProps
}: Readonly<CreateButtonProps>): React.ReactElement {
  return (
    <Button
      data-testid={testId}
      leading
      size="large"
      variant="primary"
      {...buttonProps}
      onClick={onClick}
      icon={iconMap[icon]}
    >
      {children}
    </Button>
  );
}

export default CreateButton;
