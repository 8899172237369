import { Tooltip } from '@hexa-ui/components';
import { Icon, Paragraph, Skeleton } from 'components';
import { LabelTextContainer, LabelWrapper, TooltipContainer } from 'components/label/Label.styles';
import { LabelProps } from 'components/types/Label';
import { useIntl } from 'react-intl';

const Label = ({
  id,
  children,
  text,
  tooltipText,
  tooltipPlacement = 'top',
  isOptional,
  isError,
}: LabelProps): React.ReactElement => {
  const { formatMessage } = useIntl();

  return (
    <Skeleton
      id={`skeleton-${id}`}
      variant="body"
      imageShape="rectangle"
      height="16px"
      width="160px"
      mb="1"
    >
      <LabelWrapper id={id} data-testid={id}>
        <LabelTextContainer>
          <Paragraph
            text={text}
            size="small"
            colorType={isError ? 'error' : 'primary'}
            weight="semibold"
          />

          {text && isOptional && (
            <Paragraph
              text={formatMessage({ id: 'ItemDetailsPage.OPTIONAL' })}
              size="small"
              colorType="disabled"
            />
          )}
          {tooltipText && (
            <TooltipContainer>
              <Tooltip placement={tooltipPlacement} text={tooltipText}>
                <Icon name="Info" size="medium" color="rgb(153, 153, 153)" />
              </Tooltip>
            </TooltipContainer>
          )}
        </LabelTextContainer>
        {children}
      </LabelWrapper>
    </Skeleton>
  );
};

export default Label;
