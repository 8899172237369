import { SearchByEnum } from 'stores/itemsList/ItemsListState';
import type {
  CreateSearchByOptionsParams,
  CreateSearchByOptionsReturn,
} from './createSearchByOptions.types';

const BASE_SEARCH_OPTIONS_KEY = 'Filter.SEARCH_OPTIONS.';
const OPTION_VALUES = [SearchByEnum.SKUS, SearchByEnum.GTINS, SearchByEnum.VENDOR_ITEM_IDS];

export function createSearchByOptions({
  formatMessage,
  getSearchByTranslationKey,
}: CreateSearchByOptionsParams): CreateSearchByOptionsReturn {
  const searchByOptions = OPTION_VALUES.map((option) => ({
    label: formatMessage({ id: BASE_SEARCH_OPTIONS_KEY.concat(getSearchByTranslationKey(option)) }),
    value: option,
  }));

  return searchByOptions;
}
