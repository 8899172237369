import { useCallback } from 'react';
import { ClipboardButtonStyle } from './ClipboardButton.styles';
import { ClipboardButtonProps } from './ClipboardButton.types';

export const ClipboardButton = ({
  textToCopy,
  onSuccess,
  onError,
  ...rest
}: ClipboardButtonProps) => {
  const onCopy = useCallback(() => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        onSuccess && onSuccess();
      })
      .catch(() => {
        onError && onError();
      });
  }, [textToCopy, onSuccess, onError]);
  return <ClipboardButtonStyle onClick={onCopy} {...rest} />;
};
