import { createStore } from 'effector';
import { resetFilter, resetItemsListStore, setFilter } from './ItemsListEvents';
import ItemsListState, { SearchByEnum } from './ItemsListState';

const initialState: ItemsListState = {
  items: [],
  filter: {
    status: '',
    sellers: [],
    inputText: '',
    searchBy: SearchByEnum.SKUS,
  },
  isLoading: true,
};

const itemsFilterStore = createStore(initialState)
  .on(setFilter, (state, { status, sellers, inputText, searchBy }) => ({
    ...state,
    filter: {
      status,
      sellers,
      inputText,
      searchBy,
    },
  }))
  .on(resetFilter, (state, { defaultSeller }) => ({
    ...state,
    filter: {
      ...initialState.filter,
      sellers: [defaultSeller],
    },
  }))
  .reset(resetItemsListStore);

export default itemsFilterStore;
