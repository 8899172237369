import { ListItemWrapper } from 'components/list/List.styles';
import { ListItemProps } from 'components/types/ListItem.d';

const ListItem = ({
  id,
  children,
  disabled = false,
  variant = 'base',
  mt,
  mr,
  mb,
  ml,
  onClick = () => {
    // do nothing
  },
}: ListItemProps): React.ReactElement => {
  return (
    <ListItemWrapper
      id={id}
      data-testid={id}
      disabled={disabled}
      variant={variant}
      mt={mt}
      mr={mr}
      mb={mb}
      ml={ml}
      onClick={onClick}
    >
      {children}
    </ListItemWrapper>
  );
};

export default ListItem;
