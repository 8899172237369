import { IApi } from 'Api/Api';

type FetcherParams<ParamsT> = {
  params?: ParamsT;
  throwOnError?: boolean;
};

export class Fetcher {
  constructor(private readonly apiService: IApi) {}

  get =
    <ResponseT, ParamsT = unknown>({ params, throwOnError }: FetcherParams<ParamsT> = {}) =>
    (url: string): Promise<ResponseT> =>
      this.apiService.get<ResponseT, ParamsT>({ url, params, throwOnError });
}
