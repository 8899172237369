import styled from 'styled-components';

export const FilterPopover = styled.div`
  position: absolute;
  left: 0;
  top: 52px;
  width: 534px;
  height: 484px;
  border: none;
  border-radius: 24px;
  z-index: 11;
  background-color: white;
  box-shadow: 0px 0px 16px 0px #00000029;
  display: grid;
  grid-template-columns: 214px 320px;
  grid-template-rows: 412px 72px;
  grid-template-areas:
    'a b'
    'c c';
`;

export const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  grid-area: a;
  border-right: 1px solid #dbdada;
`;

export const NavItem = styled.div<{ selected: boolean }>`
  & > div > div > div:nth-child(2) > svg {
    fill: ${({ selected }) => selected && '#047af1'};
  }
  & > div > div {
    background-color: ${({ selected }) => selected && '#F5F2FD'};
  }
`;

export const NavHeader = styled.div`
  padding: 16px;
`;

export const TextItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const FilterSection = styled.div`
  grid-area: b;
  display: flex;
  flex-direction: column;
`;

export const Footer = styled.div`
  border-top: 1px solid #dbdada;
  grid-area: c;
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
`;

export const FooterButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
